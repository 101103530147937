"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeList = exports.switchStatus = exports.statusList = exports.showHiddenStatus = exports.roleListStatus = exports.page = exports.fromList = exports.formConfigIds = exports.deletedOrNormal = exports.configCategory = exports.categoryType = exports.NUM_Range = void 0;
var _utils = require("@/utils");
/*
/ 全局静态变量定义
切勿随意修改数组次序，很多地方已下标方式使用
 */
// internationalization
var page = exports.page = {
  limit: [20, 40, 60, 80, 100],
  page: 1,
  layout: 'total, sizes, prev, pager, next, jumper'
};

/**
 * 无限极分类type
 * @type {{product: number, attachment: number, menu: number, article: number, operator: number}}
 */
var categoryType = exports.categoryType = [
// 1 产品分类，2 文章分类 3 附件分类， 4 设置分类， 5 菜单分类， 6 配置分类， 7 秒杀配置
{
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbkn00'),
  value: 1,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblfk0')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblio0'),
  value: 2,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblkg0')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblm00'),
  value: 3,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblnw0')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblqg0'),
  value: 4,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbls80')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblug0'),
  value: 5,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblww0')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqblyc0'),
  value: 6,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbm000')
}, {
  name: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbm1k0'),
  value: 7,
  shortName: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbm380')
}];
var roleListStatus = exports.roleListStatus = [{
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbmpc0'),
  value: ''
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbn3k0'),
  value: 1
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbn740'),
  value: 0
}];
var showHiddenStatus = exports.showHiddenStatus = [{
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbn3k0'),
  value: '‘1’'
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbn740'),
  value: '‘0’'
}];
var switchStatus = exports.switchStatus = [{
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbn9s0'),
  value: 1
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnbk0'),
  value: 0
}];
var deletedOrNormal = exports.deletedOrNormal = [{
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnd40'),
  value: 0
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnfg0'),
  value: 1
}];

/**
 * 暂时弃用
 * @type {*[]}
 */
var configCategory = exports.configCategory = [{
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnho0'),
  value: '0'
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnjo0'),
  value: '1'
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnks0'),
  value: '2'
}, {
  label: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnm80'),
  value: '3'
}];

/**
 * 表单配置集合集中配置
 * @type {{id: number, dis: string}[]}
 */
var formConfigIds = exports.formConfigIds = [{
  id: 84,
  dis: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbno40')
}, {
  id: 86,
  dis: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbm1k0')
}];

/**
 * 时间选择器
 */
var fromList = exports.fromList = {
  title: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnps0'),
  custom: true,
  fromTxt: [{
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbmpc0'),
    val: ''
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnrc0'),
    val: 'today'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnsk0'),
    val: 'yesterday'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnu00'),
    val: 'lately7'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnvo0'),
    val: 'lately30'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnx00'),
    val: 'month'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnyo0'),
    val: 'year'
  }]
};

/**
 * 统计管理时间选择器
 */
var timeList = exports.timeList = {
  title: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnps0'),
  custom: true,
  fromTxt: [{
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnsk0'),
    val: ""
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnu00'),
    val: 'lately7'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbnvo0'),
    val: 'lately30'
  }]
};

/**
 * 商户审核状态
 */
var statusList = exports.statusList = {
  title: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbo040'),
  custom: true,
  fromTxt: [{
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbmpc0'),
    val: ''
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbo1c0'),
    val: '1'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbo2s0'),
    val: '2'
  }, {
    text: (0, _utils.getLocaleI18n)('src.utils.constants.5theixqbo4k0'),
    val: '3'
  }]
};

/**
 *  数值范围
 */
var NUM_Range = exports.NUM_Range = {
  min: 1,
  max: 9999
};