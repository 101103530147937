var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:user:page:list"],
                      expression: "['platform:user:page:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "userFrom",
                      attrs: {
                        inline: "",
                        size: "small",
                        model: _vm.userFrom,
                        "label-position": _vm.labelPosition,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
                            },
                            [
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "user.list.index.5tqlp5tzoas0"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "user.list.index.5tqlp5tzpbg0"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.keywords,
                                          callback: function ($$v) {
                                            _vm.keywords =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "keywords",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                _vm._b({}, "el-col", _vm.grid, false),
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "user.list.index.5tqlp5tzprw0"
                                        ),
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "selWidth",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "user.list.index.5tqlp5tzpxc0"
                                          ),
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.phone,
                                          callback: function ($$v) {
                                            _vm.phone =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.collapse
                            ? [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "user.list.index.5tqlp5tzq1s0"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "user.list.index.5tqlp5tzq9c0"
                                                  ),
                                                  clearable: "",
                                                  filterable: "",
                                                  multiple: "",
                                                },
                                                model: {
                                                  value: _vm.labelData,
                                                  callback: function ($$v) {
                                                    _vm.labelData =
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                  },
                                                  expression: "labelData",
                                                },
                                              },
                                              _vm._l(
                                                _vm.labelLists,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      value: item.id,
                                                      label: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "手機號類型" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { placeholder: "選擇" },
                                                model: {
                                                  value:
                                                    _vm.userFrom
                                                      .phoneLengthCheck,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "phoneLengthCheck",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.phoneLengthCheck",
                                                },
                                              },
                                              _vm._l(
                                                _vm.phoneLengthCheckList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      value: item.label,
                                                      label: item.name,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "user.list.index.5tqlp5tzqds0"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticClass: "selWidth",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "user.list.index.5tqlp5tzq9c0"
                                                  ),
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.userFrom.payCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "payCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.payCount",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "",
                                                    label: _vm.$t(
                                                      "user.list.index.5tqlp5tzqi40"
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "0",
                                                    label: "0",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "1",
                                                    label: "1+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "2",
                                                    label: "2+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "3",
                                                    label: "3+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "4",
                                                    label: "4+",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("el-option", {
                                                  attrs: {
                                                    value: "5",
                                                    label: "5+",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "user.list.index.5tqlp5tzqlw0"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value: _vm.userFrom.sex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "sex",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "userFrom.sex",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzqi40"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzqps0"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzqug0"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "2" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzqzk0"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "3" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzr4g0"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-col",
                                      _vm._b({}, "el-col", _vm.grid, false),
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t(
                                                "user.list.index.5tqlp5tzr900"
                                              ),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticClass: "selWidth",
                                                attrs: { type: "button" },
                                                model: {
                                                  value:
                                                    _vm.userFrom.isPromoter,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userFrom,
                                                      "isPromoter",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userFrom.isPromoter",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzqi40"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "1" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzre00"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-radio-button",
                                                  { attrs: { label: "0" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "user.list.index.5tqlp5tzrhw0"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "timeBox",
                                        attrs: {
                                          label: _vm.$t(
                                            "user.list.index.5tqlp5tzrnw1"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticClass: "selWidth",
                                          attrs: {
                                            align: "right",
                                            "unlink-panels": "",
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "daterange",
                                            placement: "bottom-end",
                                            placeholder: _vm.$t(
                                              "user.list.index.5tqlp5tzrro0"
                                            ),
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          on: { change: _vm.onchangeTimea },
                                          model: {
                                            value: _vm.firstLoginTimeVal,
                                            callback: function ($$v) {
                                              _vm.firstLoginTimeVal = $$v
                                            },
                                            expression: "firstLoginTimeVal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 18,
                                      xl: 18,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "timeBox",
                                        attrs: {
                                          label: _vm.$t(
                                            "user.list.index.5tqlp5tzrnw2"
                                          ),
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          staticClass: "selWidth",
                                          attrs: {
                                            align: "right",
                                            "unlink-panels": "",
                                            "value-format": "yyyy-MM-dd",
                                            format: "yyyy-MM-dd",
                                            size: "small",
                                            type: "daterange",
                                            placement: "bottom-end",
                                            placeholder: _vm.$t(
                                              "user.list.index.5tqlp5tzrro0"
                                            ),
                                            "picker-options": _vm.pickerOptions,
                                          },
                                          on: { change: _vm.onchangeTimeb },
                                          model: {
                                            value: _vm.lastLoginTimeVal,
                                            callback: function ($$v) {
                                              _vm.lastLoginTimeVal = $$v
                                            },
                                            expression: "lastLoginTimeVal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  _vm._b({}, "el-col", _vm.grid, false),
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "user.list.index.5tqlp5tzryo0"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "user.list.index.5tqlp5tzq9c0"
                                              ),
                                              clearable: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getList(1)
                                              },
                                            },
                                            model: {
                                              value: _vm.userFrom.registerType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.userFrom,
                                                  "registerType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "userFrom.registerType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.registerTypeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "text-right userFrom userbtn",
                              attrs: { xs: 24, sm: 24, md: 24, lg: 6, xl: 6 },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        plain: "",
                                        size: "small",
                                      },
                                      on: { click: _vm.exportAllData },
                                    },
                                    [_vm._v("導出")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "ios-search",
                                        label: "default",
                                        size: "small",
                                      },
                                      on: { click: _vm.userSearchs },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("user.list.index.5tqlp5tzs340")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ResetSearch mr10",
                                      attrs: { size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reset("userFrom")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("user.list.index.5tqlp5tzsbc0")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "ivu-ml-8",
                                      on: {
                                        click: function ($event) {
                                          _vm.collapse = !_vm.collapse
                                        },
                                      },
                                    },
                                    [
                                      !_vm.collapse
                                        ? [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "user.list.index.5tqlp5tzsfw0"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-down",
                                            }),
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "user.list.index.5tqlp5tzsts0"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-arrow-up",
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn_bt" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:user:tag"],
                          expression: "['platform:user:tag']",
                        },
                      ],
                      staticClass: "mr10",
                      attrs: {
                        disabled: !_vm.multipleSelectionAll.length,
                        size: "small",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setBatch("label")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("user.list.index.5tqlp5tzt200")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "platform:coupon:can:send:list",
                            "platform:coupon:batch:send",
                          ],
                          expression:
                            "['platform:coupon:can:send:list', 'platform:coupon:batch:send']",
                        },
                      ],
                      attrs: {
                        disabled: !_vm.multipleSelectionAll.length,
                        size: "small",
                      },
                      on: { click: _vm.sendCoupon },
                    },
                    [_vm._v(_vm._s(_vm.$t("user.list.index.5tqlp5tzt6s0")))]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "select-all": _vm.selectAll,
                select: _vm.selectOne,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tzr900"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterIsPromoter")(
                                        props.row.isPromoter
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tzt9c0"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tztb00"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.lastLoginTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tzte40"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("tagFilter")(props.row.tagId))
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tztg80"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.province + props.row.city
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: {
                                  width: "100%",
                                  display: "flex",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  label: _vm.$t("user.list.index.5tqlp5tzti40"),
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.mark)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("ID")
                ? _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "80" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("头像")
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.list.index.5tqlp5tztjw0"),
                      "min-width": "80",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "demo-image__preview" },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "36px",
                                      height: "36px",
                                    },
                                    attrs: {
                                      src: scope.row.avatar,
                                      "preview-src-list": [scope.row.avatar],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3573681484
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("姓名")
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.list.index.5tqlp5tztnw0"),
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.isLogoff == true ? "red" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(scope.row.nickname)
                                    ) +
                                      " | " +
                                      _vm._s(_vm._f("sexFilter")(scope.row.sex))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        scope.row.isLogoff == true ? "red" : "",
                                    },
                                    [_vm._v("|")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v("(已注销)"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1602246744
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("推荐人")
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.list.index.5tqlp5tztp40"),
                      "min-width": "130",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("filterEmpty")(scope.row.spreadName)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3565175388
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("手机号")
                ? _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("user.list.index.5tqlp5tztrk0"),
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3743808278
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("余额")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "nowMoney",
                      label: _vm.$t("user.list.index.5tqlp5tztv00"),
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("积分")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "integral",
                      label: _vm.$t("user.list.index.5tqlp5tztws0"),
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("注册类型")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "registerType",
                      label: _vm.$t("user.list.index.5tqlp5tztz40"),
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    color: _vm.filterRegisterType(
                                      scope.row.registerType
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("registerTypeFilter")(
                                        scope.row.registerType
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2186251029
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("user.list.index.5tqlp5tzu1o0"),
                  "min-width": "130",
                  fixed: "right",
                  align: "center",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["platform:user:update"],
                                expression: "['platform:user:update']",
                              },
                            ],
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.editUser(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("user.list.index.5tqlp5tzu480"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "platform:user:detail",
                                  "platform:user:operate:integer",
                                  "platform:user:operate:balance",
                                  "platform:user:tag",
                                  "platform:retail:store:update:user:spread",
                                  "platform:retail:store:clean:user:spread",
                                ],
                                expression:
                                  "[\n              'platform:user:detail',\n              'platform:user:operate:integer',\n              'platform:user:operate:balance',\n              'platform:user:tag',\n              'platform:retail:store:update:user:spread',\n              'platform:retail:store:clean:user:spread',\n            ]",
                              },
                            ],
                            attrs: { trigger: "click" },
                          },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("user.list.index.5tqlp5tzu780"))
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _vm.checkPermi(["platform:user:detail"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onDetails(scope.row.id)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzubg0"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:user:operate:integer",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editPoint(
                                              scope.row,
                                              "integral"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzudk0"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:user:operate:balance",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.editPoint(
                                              scope.row,
                                              "balance"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzug40"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi(["platform:user:tag"])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setBatch(
                                              "label",
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzuj40"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.checkPermi([
                                  "platform:retail:store:update:user:spread",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.setExtension(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzuno0"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.spreadUid &&
                                scope.row.spreadUid > 0 &&
                                _vm.checkPermi([
                                  "platform:retail:store:clean:user:spread",
                                ])
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clearSpread(scope.row)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "user.list.index.5tqlp5tzupk0"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [15, 30, 45, 60],
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
          style: { top: _vm.collapse ? 570 + "px" : 270 + "px" },
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("user.list.index.5tqlp5tzuuw0")))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("user.list.index.5tqlp5tzuys0")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("user.list.index.5tqlp5tzv140"),
            visible: _vm.extensionVisible,
            width: "500px",
            "before-close": _vm.handleCloseExtension,
          },
          on: {
            "update:visible": function ($event) {
              _vm.extensionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formExtension",
              staticClass: "formExtension mt20",
              attrs: {
                model: _vm.formExtension,
                rules: _vm.ruleInline,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户:", prop: "image" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upLoadPicBox",
                      on: { click: _vm.modalPicTap },
                    },
                    [
                      _vm.selectedUser.image
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                [
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("img", {
                                      staticClass: "pictrue",
                                      attrs: { src: _vm.selectedUser.image },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-col", { attrs: { span: 12 } }, [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.selectedUser.nickname)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "upLoad" }, [
                            _c("i", {
                              staticClass: "el-icon-camera cameraIconfont",
                            }),
                          ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubExtension("formExtension")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzv340")) +
                      " " +
                      _vm._s(_vm.$t("user.list.index.5tqlp5tzv980"))
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("user.list.index.5tqlp5tzvao0"),
            visible: _vm.userVisible,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
          },
        },
        [
          _vm.userVisible
            ? _c("user-list", { on: { getTemplateRow: _vm.getTemplateRow } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzvd40")) +
                      " " +
                      _vm._s(_vm.$t("user.list.index.5tqlp5tzvfc0"))
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzv340")) +
                      " " +
                      _vm._s(_vm.$t("user.list.index.5tqlp5tzv980"))
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("user.list.index.5tqlp5tzvgo0"),
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "dynamicValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.dynamicValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "groupId",
                    label: _vm.$t("user.list.index.5tqlp5tzvik0"),
                    rules: [
                      {
                        required: true,
                        message: _vm.$t("user.list.index.5tqlp5tzvl00"),
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        placeholder: _vm.$t("user.list.index.5tqlp5tzvn40"),
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.dynamicValidateForm.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dynamicValidateForm, "groupId", $$v)
                        },
                        expression: "dynamicValidateForm.groupId",
                      },
                    },
                    _vm._l(_vm.labelLists, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v(
                  _vm._s(_vm.$t("user.list.index.5tqlp5tzvd40")) +
                    " " +
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzvfc0"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.submitForm("dynamicValidateForm")
                      },
                      expression:
                        "\n          () => {\n            submitForm('dynamicValidateForm');\n          }\n        ",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzv340")) +
                      " " +
                      _vm._s(_vm.$t("user.list.index.5tqlp5tzv980"))
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("user.list.index.5tqlp5tzu480"),
            visible: _vm.visible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("edit-from", {
                attrs: { userInfo: _vm.userInfo },
                on: { resetForm: _vm.resetForm },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.type === "integral"
                ? _vm.$t("user.list.index.5tqlp5tztws0")
                : _vm.$t("user.list.index.5tqlp5tztv00"),
            visible: _vm.VisiblePoint,
            width: "500px",
            "close-on-click-modal": false,
            "before-close": _vm.handlePointClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.VisiblePoint = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingPoint,
                  expression: "loadingPoint",
                },
              ],
              ref: "PointValidateForm",
              staticClass: "demo-dynamic",
              attrs: { model: _vm.PointValidateForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.type === "integral"
                        ? _vm.$t("user.list.index.5tqlp5tztws0")
                        : _vm.$t("user.list.index.5tqlp5tztv00"),
                    required: "",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.type === "integral"
                          ? _vm.userInfo.integral
                          : _vm.userInfo.nowMoney
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.type === "integral"
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("user.list.index.5tqlp5tzudk0"),
                          required: "",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.PointValidateForm.operateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.PointValidateForm,
                                  "operateType",
                                  $$v
                                )
                              },
                              expression: "PointValidateForm.operateType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "add" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("user.list.index.5tqlp5tzvpw0"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "sub" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("user.list.index.5tqlp5tzvtc0"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("user.list.index.5tqlp5tztws0"),
                          required: "",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            type: "text",
                            "step-strictly": "",
                            min: 0,
                            max: 999999,
                          },
                          model: {
                            value: _vm.PointValidateForm.integral,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.PointValidateForm,
                                "integral",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "PointValidateForm.integral",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("user.list.index.5tqlp5tzug40"),
                          required: "",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.PointValidateForm.operateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.PointValidateForm,
                                  "operateType",
                                  $$v
                                )
                              },
                              expression: "PointValidateForm.operateType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "add" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("user.list.index.5tqlp5tzvpw0"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-radio", { attrs: { label: "sub" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("user.list.index.5tqlp5tzvtc0"))
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("user.list.index.5tqlp5tztv00"),
                          required: "",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            type: "text",
                            precision: 2,
                            step: 0.1,
                            min: 0,
                            max: 999999,
                          },
                          model: {
                            value: _vm.PointValidateForm.money,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.PointValidateForm,
                                "money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "PointValidateForm.money",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handlePointClose } }, [
                _vm._v(
                  _vm._s(_vm.$t("user.list.index.5tqlp5tzvd40")) +
                    " " +
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzvfc0"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                  on: {
                    click: function ($event) {
                      return _vm.submitPointForm("PointValidateForm")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("user.list.index.5tqlp5tzv340")) +
                      " " +
                      _vm._s(_vm.$t("user.list.index.5tqlp5tzv980"))
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("detail-user", { ref: "userDetailFrom" }),
      _vm._v(" "),
      _vm.visibleCoupon
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("user.list.index.5tqlp5tzvv00"),
                visible: _vm.visibleCoupon,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleCoupon = $event
                },
              },
            },
            [
              _vm.visibleCoupon
                ? _c("coupon-List", {
                    ref: "couponList",
                    attrs: { checkedIds: _vm.checkedIds },
                    on: { sendSuccess: _vm.sendSuccess },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }