"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.agreementInfoApi = agreementInfoApi;
exports.agreementSaveApi = agreementSaveApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 协议管理-- 详情
 */
function agreementInfoApi(data) {
  return _request.default.get("admin/platform/agreement/".concat(data));
}

/**
 * @description 协议管理-- 保存
 */
function agreementSaveApi(save, data) {
  // return request.post(`admin/platform/agreement/${save}`, data);
  return (0, _request.default)({
    url: "admin/platform/agreement/".concat(save),
    method: 'POST',
    data: data
  });
}