"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _devise = require("@/api/devise");
var _systemConfig = require("@/api/systemConfig");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _clipboard = _interopRequireDefault(require("clipboard"));
var _index = _interopRequireDefault(require("../components/mobilePage/index.js"));
var _index2 = _interopRequireDefault(require("../components/mobileConfig/index.js"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var idGlobal = 0;
var _default = exports.default = {
  //inject: ['reload'],
  name: 'creatDevise.vue',
  data: function data() {
    return {
      footerTop: 0,
      //底部导航top值
      clientHeight: '',
      //页面动态高度
      rollHeight: '',
      leftMenu: [],
      // 左侧菜单
      lConfig: [],
      // 左侧组件
      mConfig: [],
      // 中间组件渲染
      rConfig: [],
      // 右侧组件配置
      activeConfigName: '',
      propsObj: {},
      // 组件传递的数据,
      activeIndex: -100,
      // 选中的下标
      number: 0,
      pageId: 0,
      category: [],
      tabList: [{
        title: this.$t('pagediy.devise.creatDevise.5trmh81e8ng0'),
        key: 0
      }],
      tabCur: 0,
      urlList: [],
      footActive: false,
      loading: false,
      isSearch: false,
      isFooter: false,
      isTab: false,
      isComb: false,
      isFllow: false,
      is_diy: 0,
      name: '',
      //模板名称
      nameTop: '',
      //头部模板名称表单提交
      nameContent: '',
      //另存模板名称
      isShow: false,
      visible: false,
      isReast: false,
      isDefault: 0,
      pageFooter: {
        name: 'pageFoot',
        setUp: {
          tabVal: '0'
        },
        status: {
          title: this.$t('pagediy.devise.creatDevise.5trmh81e8q00'),
          name: 'status',
          status: false
        },
        txtColor: {
          title: this.$t('pagediy.devise.creatDevise.5trmh81e8r40'),
          name: 'txtColor',
          default: [{
            item: '#282828'
          }],
          color: [{
            item: '#282828'
          }]
        },
        activeTxtColor: {
          title: this.$t('pagediy.devise.creatDevise.5trmh81e8sk0'),
          name: 'txtColor',
          default: [{
            item: '#F62C2C'
          }],
          color: [{
            item: '#F62C2C'
          }]
        },
        bgColor: {
          title: this.$t('pagediy.devise.creatDevise.5trmh81e8to0'),
          name: 'bgColor',
          default: [{
            item: '#fff'
          }],
          isFoot: true,
          color: [{
            item: '#fff'
          }]
        },
        menuList: [{
          imgList: [require('@/assets/imgs/foot-002.png'), require('@/assets/imgs/foot-001.png')],
          name: this.$t('pagediy.devise.creatDevise.5trmh81e8uo0'),
          link: '/pages/index/index'
        }, {
          imgList: [require('@/assets/imgs/foot-004.png'), require('@/assets/imgs/foot-003.png')],
          name: this.$t('pagediy.devise.creatDevise.5trmh81e8vw0'),
          link: '/pages/goods_cate/index'
        }, {
          imgList: [require('@/assets/imgs/foot-006.png'), require('@/assets/imgs/foot-005.png')],
          name: this.$t('pagediy.devise.creatDevise.5trmh81e8xg0'),
          link: '/pages/discover_index/index'
        }, {
          imgList: [require('@/assets/imgs/foot-008.png'), require('@/assets/imgs/foot-007.png')],
          name: this.$t('pagediy.devise.creatDevise.5trmh81e8yw0'),
          link: '/pages/order_addcart/order_addcart'
        }, {
          imgList: [require('@/assets/imgs/foot-0010.png'), require('@/assets/imgs/foot-009.png')],
          name: this.$t('pagediy.devise.creatDevise.5trmh81e9040'),
          link: '/pages/user/index'
        }]
      }
    };
  },
  components: _objectSpread(_objectSpread({
    draggable: _vuedraggable.default
  }, _index.default), _index2.default),
  watch: {
    nameTxt: function nameTxt(val) {
      //直接赋值给本地data中的属性，就不会报错啦
      this.name = val;
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    titleTxt: function titleTxt(state) {
      return state.mobildConfig.pageTitle || (0, _utils.getLocaleI18n)('pagediy.devise.creatDevise.5trmh81e8uo0');
    },
    nameTxt: function nameTxt(state) {
      return state.mobildConfig.pageName;
    },
    showTxt: function showTxt(state) {
      return state.mobildConfig.pageShow;
    },
    colorTxt: function colorTxt(state) {
      return state.mobildConfig.pageColor;
    },
    picTxt: function picTxt(state) {
      return state.mobildConfig.pagePic;
    },
    colorPickerTxt: function colorPickerTxt(state) {
      return state.mobildConfig.pageColorPicker;
    },
    tabValTxt: function tabValTxt(state) {
      return state.mobildConfig.pageTabVal;
    },
    picUrlTxt: function picUrlTxt(state) {
      return state.mobildConfig.pagePicUrl;
    },
    bgType: function bgType(state) {
      return state.mobildConfig.bgType;
    },
    swiperBgConfig: function swiperBgConfig(state) {
      return state.mobildConfig.swiperBgConfig;
    },
    returnAddress: function returnAddress(state) {
      return state.mobildConfig.returnAddress;
    },
    titleColor: function titleColor(state) {
      return state.mobildConfig.titleColor;
    },
    //标题字体颜色
    titleBgColor: function titleBgColor(state) {
      return state.mobildConfig.titleBgColor;
    } //标题背景色
  })), (0, _vuex.mapGetters)(['frontDomain'])), {}, {
    //标题样式
    pageTitle: function pageTitle() {
      return [{
        backgroundColor: this.titleBgColor
      }, {
        color: this.titleColor
      }];
    }
  }),
  created: function created() {
    var hideArr = ['拼团', '文章列表', '底部导航', '新闻播报', '种草社区', '小程序直播', '秒杀'];
    this.lConfig = this.objToArr(_index.default).filter(function (item) {
      return !hideArr.includes(item.cname);
    });
    // console.log(this.lConfig)
  },
  mounted: function mounted() {
    var _this = this;
    //监听事件
    document.addEventListener('keydown', this.saveDiy);
    this.pageId = Number(this.$route.params.id);
    if (this.pageId === 0) this.visible = true; //新增的时候修改模板名称显示出来
    this.nameTop = this.nameTxt;
    this.name = this.nameTxt;
    this.$nextTick(function () {
      _this.arraySort();
      if (_this.pageId != 0) {
        //this.getDefaultConfig();
      } else {
        _this.showTitle();
      }
      //this.clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      _this.clientHeight = "".concat(document.documentElement.clientHeight) - 76; //获取浏览器可视区域高度
      var H = "".concat(document.documentElement.clientHeight) - 180;
      _this.rollHeight = H > 650 ? 650 : H;
      var that = _this;
      window.onresize = function () {
        that.clientHeight = "".concat(document.documentElement.clientHeight) - 76;
        var H = "".concat(document.documentElement.clientHeight) - 180;
        that.rollHeight = H > 650 ? 650 : H;
        that.footerTop = that.rollHeight + 196;
      };
      //66+75+55=196
      _this.footerTop = _this.rollHeight + 196;
    });
    this.$nextTick(function () {
      var _this2 = this;
      var clipboard = new _clipboard.default('.copy-data');
      clipboard.on('success', function () {
        _this2.$message.success(_this2.$t('pagediy.devise.creatDevise.5trmh81e91c0'));
      });
    });
    if (this.$route.params.id !== '0') this.getInfo();
    if (!localStorage.getItem('mediaDomain')) this.getMediadomain();
    this.getQRcode();
  },
  beforeDestroy: function beforeDestroy() {
    //销毁时移除监听
    document.removeEventListener('keydown', this.saveDiy);
    this.$store.commit('mobildConfig/titleUpdata', '');
    this.$store.commit('mobildConfig/nameUpdata', '');
    this.$store.commit('mobildConfig/showUpdata', 1);
    this.$store.commit('mobildConfig/colorUpdata', 0);
    this.$store.commit('mobildConfig/picUpdata', 0);
    this.$store.commit('mobildConfig/pickerUpdata', '#f5f5f5');
    this.$store.commit('mobildConfig/radioUpdata', 0);
    this.$store.commit('mobildConfig/picurlUpdata', '');
    this.$store.commit('mobildConfig/SETEMPTY');
  },
  destroyed: function destroyed() {
    this.$store.commit('mobildConfig/titleUpdata', '');
    this.$store.commit('mobildConfig/nameUpdata', '');
    this.$store.commit('mobildConfig/showUpdata', 1);
    this.$store.commit('mobildConfig/colorUpdata', 0);
    this.$store.commit('mobildConfig/picUpdata', 0);
    this.$store.commit('mobildConfig/pickerUpdata', '#f5f5f5');
    this.$store.commit('mobildConfig/radioUpdata', 0);
    this.$store.commit('mobildConfig/picurlUpdata', '');
    this.$store.commit('mobildConfig/SETEMPTY');
  },
  methods: {
    //ctrl+s保存
    saveDiy: function saveDiy(e) {
      var key = window.event.keyCode ? window.event.keyCode : window.event.which;
      if (key === 83 && e.ctrlKey) {
        this.saveConfig();
        e.preventDefault(); //阻止浏览器默认事件
      }
    },
    onChange: function onChange() {},
    onClick: function onClick() {
      // this.isShow = true
    },
    //取消
    onCancel: function onCancel() {
      this.isShow = false;
      this.visible = false;
      //this.nameTop = ''
    },
    //模板名称
    changName: function changName(val) {
      //this.$store.commit('mobildConfig/UPNAME', val);
    },
    //预览二维码
    getQRcode: function getQRcode() {
      document.getElementById('diyQrcode').innerHTML = '';
      new _qrcodejs.default('diyQrcode', {
        width: 135,
        height: 135,
        text: this.frontDomain + '?id=' + this.pageId
      });
    },
    //diy详情
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _devise.pagediyInfoApi)(this.$route.params.id).then(function (res) {
        var data = res;
        // console.log('data',data)
        //更新页面设置中的参数
        _this3.$store.commit('mobildConfig/titleUpdata', data.title);
        _this3.$store.commit('mobildConfig/UPBGTYPE', data.bgType);
        _this3.$store.commit('mobildConfig/UPSWIPERBGConfig', data.swiperBgConfig);
        _this3.$store.commit('mobildConfig/nameUpdata', data.name);
        _this3.$store.commit('mobildConfig/showUpdata', data.isShow);
        _this3.$store.commit('mobildConfig/colorUpdata', data.isBgColor || 0);
        _this3.$store.commit('mobildConfig/picUpdata', data.isBgPic || 0);
        _this3.$store.commit('mobildConfig/pickerUpdata', data.colorPicker || '#f5f5f5');
        _this3.$store.commit('mobildConfig/radioUpdata', data.bgTabVal || 0);
        _this3.$store.commit('mobildConfig/picurlUpdata', data.bgPic || '');
        _this3.$store.commit('mobildConfig/returnAddressUpdata', data.returnAddress);
        _this3.$store.commit('mobildConfig/titleBgColorUpdata', data.titleBgColor);
        _this3.$store.commit('mobildConfig/titleColorUpdata', data.titleColor);
        _this3.nameTop = data.name;
        _this3.isDefault = data.isDefault;
        console.log('this.isDefault', _this3.isDefault);
        _this3.defaultData(data.value);
      });
    },
    //详情接口请求后，详情数据
    defaultData: function defaultData(data) {
      var _this4 = this;
      var obj = {};
      var tempARR = [];
      var newArr = this.objToArr(data);
      function sortNumber(a, b) {
        return a.timestamp - b.timestamp;
      }
      newArr.sort(sortNumber);
      newArr.map(function (el, index) {
        if (el.name == 'home_footer') {
          _this4.isFooter = true;
        }
        if (el.name == 'headerSerch') {
          _this4.isSearch = true;
        }
        if (el.name == 'tabNav') {
          _this4.isTab = true;
        }
        if (el.name == 'homeComb') {
          _this4.isComb = true;
        }
        if (el.name == 'goodList') {
          var storage = window.localStorage;
          storage.setItem(el.timestamp, el.selectConfig.activeValue);
        }
        el.id = 'id' + el.timestamp;
        _this4.lConfig.map(function (item, j) {
          if (el.name == item.defaultName) {
            item.num = el.timestamp;
            item.id = 'id' + el.timestamp;
            var tempItem = JSON.parse(JSON.stringify(item));
            tempARR.push(tempItem);
            obj[el.timestamp] = el;
            _this4.mConfig.push(tempItem);
            // 保存默认组件配置
            _this4.$store.commit('mobildConfig/ADDARRAY', {
              num: el.timestamp,
              val: el
            });
          }
        });
      });
      this.showTitle();
    },
    //图片域名
    getMediadomain: function getMediadomain() {
      var _this5 = this;
      (0, _systemConfig.mediadomainApi)().then(function (res) {
        localStorage.setItem('mediaDomain', res);
        _this5.$store.commit('settings/SET_mediaDomain', res);
      });
    },
    cloneDog: function cloneDog(data) {
      return _objectSpread({}, data);
    },
    // 点击显示相应的配置
    bindconfig: function bindconfig(item, index) {
      this.rConfig = [];
      var tempItem = JSON.parse(JSON.stringify(item));
      // console.log(tempItem)
      this.rConfig.push(tempItem);
      this.activeIndex = index;
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
    },
    // 模板名称保存
    saveName: function saveName(n, j) {
      if (!n) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e92g0'));
      if (j === 1) {
        //this.$store.commit('mobildConfig/UPNAME', this.nameContent);
      } else {
        this.$store.commit('mobildConfig/UPNAME', this.nameTop);
      }
      this.saveConfig(0, j);
    },
    /**
     * 保存配置
     * @param n 是否关闭页面，1是
     * @param j 是否是另存模板，1是,0只编辑模板名称
     */
    saveConfig: function saveConfig(n, j) {
      this.loading = false;
      var val = this.$store.state.mobildConfig.defaultArray;
      this.$nextTick(function () {
        this.diySaveDate(val, n, j);
      });
    },
    //提交数据
    diySaveDate: function diySaveDate(val, n, j) {
      var _this6 = this;
      // debugger
      console.log(this);
      var data = {
        value: val,
        title: this.titleTxt,
        name: j === 1 ? this.nameContent : j === 0 ? this.nameTop : this.name,
        isShow: this.showTxt ? 1 : 0,
        isBgColor: this.colorTxt ? 1 : 0,
        colorPicker: this.colorPickerTxt,
        bgType: this.bgType,
        swiperBgConfig: this.swiperBgConfig,
        bgPic: this.picUrlTxt,
        bgTabVal: this.tabValTxt,
        isBgPic: this.picTxt ? 1 : 0,
        returnAddress: this.returnAddress,
        titleColor: this.titleColor,
        titleBgColor: this.titleBgColor,
        id: this.pageId ? this.pageId : null,
        isDefault: this.isDefault
      };
      if (!data.name) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e93g0'));
      this.pageId === 0 || j === 1 ? (0, _devise.pagediySaveApi)(data).then(function (res) {
        _this6.loading = false;
        _this6.pageId = res.id;
        _this6.$message.success(_this6.$t('pagediy.devise.creatDevise.5trmh81e94g0'));
        _this6.getQRcode();
        _this6.onCancel();
        _this6.close(n);
      }).catch(function () {
        _this6.loading = false;
      }) : (0, _devise.pagediyUpdateApi)(data).then(function (res) {
        _this6.loading = false;
        _this6.$message.success(_this6.$t('pagediy.devise.creatDevise.5trmh81e95s0'));
        _this6.close(n);
        _this6.onCancel();
      }).catch(function () {
        _this6.loading = false;
      });
    },
    //保存关闭
    close: function close(n) {
      if (n === 1) {
        var userAgent = navigator.userAgent;
        if (userAgent.indexOf('Firefox') != -1 || userAgent.indexOf('Chrome') != -1) {
          window.open('', '_self').close();
          window.location.href = 'about:blank';
        } else {
          window.opener = null;
          window.open('about:blank', '_self');
          window.close();
        }
      }
    },
    // 重置
    reast: function reast() {
      this.mConfig = [];
      this.rConfig = [];
      this.activeIndex = -99;
      this.getInfo();
      this.isSearch = false;
      this.isFooter = false;
      this.isTab = false;
      this.isComb = false;
    },
    // 组件返回
    config: function config(data) {
      var propsObj = this.propsObj;
      propsObj.data = data;
      propsObj.name = this.activeConfigName;
    },
    // 对象转数组
    objToArr: function objToArr(data) {
      var obj = Object.keys(data);
      var m = obj.map(function (key) {
        return data[key];
      });
      return m;
    },
    // 数组排序
    arraySort: function arraySort() {
      var tempArr = [];
      var basis = {
        title: this.$t('pagediy.devise.creatDevise.5trmh81e96w0'),
        list: [],
        isOpen: true
      };
      var marketing = {
        title: this.$t('pagediy.devise.creatDevise.5trmh81e9800'),
        list: [],
        isOpen: true
      };
      var tool = {
        title: this.$t('pagediy.devise.creatDevise.5trmh81e99k0'),
        list: [],
        isOpen: true
      };
      this.lConfig.map(function (el, index) {
        if (el.type == 0) {
          basis.list.push(el);
        }
        if (el.type == 1) {
          marketing.list.push(el);
        }
        if (el.type == 2) {
          tool.list.push(el);
        }
      });
      tempArr.push(basis, marketing, tool);
      this.leftMenu = tempArr;
    },
    // 组件删除
    bindDelete: function bindDelete(item, key) {
      if (item.name == 'search_box') {
        this.isSearch = false;
      }
      if (item.name == 'home_footer') {
        this.isFooter = false;
      }
      if (item.name == 'nav_bar') {
        this.isTab = false;
      }
      if (item.name == 'home_comb') {
        this.isComb = false;
      }
      this.mConfig.splice(key, 1);
      this.rConfig.splice(0, 1);
      if (this.mConfig.length != key) {
        this.rConfig.push(this.mConfig[key]);
      } else {
        if (this.mConfig.length) {
          this.activeIndex = key - 1;
          this.rConfig.push(this.mConfig[key - 1]);
        } else {
          this.showTitle();
        }
      }
      // 删除第几个配置
      this.$store.commit('mobildConfig/DELETEARRAY', item);
    },
    //左边配置模块点击添加；
    addDom: function addDom(item, type) {
      this.addDomCon(item, type);
    },
    //中间页点击隐藏模块；
    bindHide: function bindHide(item, key) {
      var _this7 = this;
      this.$nextTick(function () {
        _this7.$set(_this7.mConfig[key], 'isShow', !_this7.mConfig[key].isShow);
      });

      //this.mConfig[key].isShow = !this.mConfig[key].isShow
      this.$store.commit('mobildConfig/UPDATESHOW', item);
    },
    //中间页点击添加模块；
    bindAddDom: function bindAddDom(item, type, index) {
      if (item.name === 'home_footer') return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9bc0'));
      var i = item;
      this.lConfig.forEach(function (j) {
        if (item.name == j.name) {
          i = j;
        }
      });
      this.addDomCon(i, type, index);
    },
    //数组元素互换位置
    swapArray: function swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    //点击上下移动；
    movePage: function movePage(item, index, type) {
      if (type) {
        if (index == 0) {
          return;
        }
      } else {
        if (index == this.mConfig.length - 1) {
          return;
        }
      }
      if (item.name == 'search_box' || item.name == 'nav_bar' || item.name == 'home_comb' || item.name == 'home_footer') {
        return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9co0'));
      }
      if (type) {
        if (this.mConfig[index - 1].name == 'search_box' || this.mConfig[index - 1].name == 'nav_bar' || this.mConfig[index - 1].name == 'home_comb') {
          return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9e80'));
        }
        this.swapArray(this.mConfig, index - 1, index);
      } else {
        this.swapArray(this.mConfig, index, index + 1);
      }
      var obj = {};
      this.rConfig = [];
      obj.oldIndex = index;
      if (type) {
        obj.newIndex = index - 1;
      } else {
        obj.newIndex = index + 1;
      }
      this.mConfig.forEach(function (el, index) {
        el.num = new Date().getTime() * 1000 + index;
        el.isShow = true;
      });
      var tempItem = JSON.parse(JSON.stringify(item));
      this.rConfig.push(tempItem);
      obj.element = item;
      obj.list = this.mConfig;
      if (type) {
        this.activeIndex = index - 1;
      } else {
        this.activeIndex = index + 1;
      }
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
      this.$store.commit('mobildConfig/defaultArraySort', obj);
    },
    // 页面标题点击
    showTitle: function showTitle() {
      this.activeIndex = -100;
      var obj = {};
      for (var i in _index2.default) {
        if (i == 'pageTitle') {
          obj = _index2.default[i];
          obj.configName = _index2.default[i].name;
          obj.cname = this.$t('pagediy.devise.creatDevise.5trmh81e88s0');
        }
      }
      var abc = obj;
      this.rConfig = [];
      this.rConfig[0] = JSON.parse(JSON.stringify(obj));
    },
    // 组件添加
    addDomCon: function addDomCon(item, type, index) {
      var _this8 = this;
      if (item.name === 'home_footer') {
        if (this.isFooter) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9bc0'));
        this.isFooter = true;
      }
      if (item.name === 'search_box') {
        if (this.isSearch) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9bc0'));
        if (this.isComb) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9f80'));
        this.isSearch = true;
      }
      if (item.name === 'nav_bar') {
        if (this.isTab) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9bc0'));
        if (this.isComb) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9f80'));
        this.isTab = true;
      }
      if (item.name === 'home_comb') {
        if (this.isComb) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9bc0'));
        if (this.isSearch || this.isTab) return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9gg0'));
        this.isComb = true;
      }
      idGlobal += 1;
      var obj = {};
      var timestamp = new Date().getTime() * 1000;
      item.num = "".concat(timestamp);
      item.id = "id".concat(timestamp);
      this.activeConfigName = item.name;
      var tempItem = JSON.parse(JSON.stringify(item));
      if (item.name == 'search_box' || item.name == 'home_comb') {
        this.rConfig = [];
        this.mConfig.unshift(tempItem);
        this.activeIndex = 0;
        this.rConfig.push(tempItem);
      } else if (item.name == 'nav_bar') {
        this.rConfig = [];
        if (this.mConfig[0] && this.mConfig[0].name === 'search_box') {
          this.mConfig.splice(1, 0, tempItem);
          this.activeIndex = 1;
        } else {
          this.mConfig.splice(0, 0, tempItem);
          this.activeIndex = 0;
        }
        this.rConfig.push(tempItem);
      } else {
        if (type) {
          this.rConfig = [];
          this.mConfig.push(tempItem);
          this.activeIndex = this.mConfig.length - 1;
          this.rConfig.push(tempItem);
        } else {
          this.mConfig.splice(index + 1, 0, tempItem);
          this.activeIndex = index;
        }
      }
      this.mConfig.forEach(function (el, index) {
        el.num = new Date().getTime() * 1000 + index;
        el.isShow = true;
      });
      // 保存组件名称
      obj.element = item;
      obj.list = this.mConfig;
      this.$nextTick(function () {
        var middle = _this8.$refs['wrapperCon'];
        var offsetHeight = _this8.$refs.mConfigItem.offsetHeight;
        if (item.name === 'search_box' || item.name === 'nav_bar' || item.name === 'home_comb') {
          middle.scrollTop = 0;
        } else {
          middle.scrollTop = middle.scrollHeight;
        }
      });
      this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
      this.$store.commit('mobildConfig/defaultArraySort', obj);
      if (type === 0) return this.$message.success(this.$t('pagediy.devise.creatDevise.5trmh81e91c0'));
    },
    //移动事件
    onMove: function onMove(e) {
      if (e.relatedContext.element.name == 'search_box') return false;
      if (e.relatedContext.element.name == 'nav_bar') return false;
      return true;
    },
    //组件拖拽事件
    onLog: function onLog(evt) {
      // 中间拖拽排序
      if (evt.moved) {
        if (evt.moved.element.name == 'search_box') {
          return this.$message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9ho0'));
        }
        if (evt.moved.element.name == 'nav_bar') {
          return this.$Message.warning(this.$t('pagediy.devise.creatDevise.5trmh81e9ho0'));
        }
        evt.moved.oldNum = this.mConfig[evt.moved.oldIndex].num;
        evt.moved.newNum = this.mConfig[evt.moved.newIndex].num;
        evt.moved.status = evt.moved.oldIndex > evt.moved.newIndex;
        this.mConfig.forEach(function (el, index) {
          el.num = new Date().getTime() * 1000 + index;
          el.isShow = true;
        });
        evt.moved.list = this.mConfig;
        this.rConfig = [];
        var item = evt.moved.element;
        var tempItem = JSON.parse(JSON.stringify(item));
        this.rConfig.push(tempItem);
        this.activeIndex = evt.moved.newIndex;
        this.$store.commit('mobildConfig/SETCONFIGNAME', item.name);
        this.$store.commit('mobildConfig/defaultArraySort', evt.moved);
      }
      // 从左向右拖拽排序
      if (evt.added) {
        var data = evt.added.element;
        var obj = {};
        var timestamp = new Date().getTime() * 1000;
        data.num = timestamp;
        this.activeConfigName = data.name;
        var _tempItem = JSON.parse(JSON.stringify(data));
        _tempItem.id = 'id' + _tempItem.num;
        this.mConfig[evt.added.newIndex] = _tempItem;
        this.rConfig = [];
        this.rConfig.push(_tempItem);
        this.mConfig.forEach(function (el, index) {
          el.num = new Date().getTime() * 1000 + index;
          el.isShow = true;
        });
        evt.added.list = this.mConfig;
        this.activeIndex = evt.added.newIndex;
        // 保存组件名称
        this.$store.commit('mobildConfig/SETCONFIGNAME', data.name);
        this.$store.commit('mobildConfig/defaultArraySort', evt.added);
      }
    }
  }
};