function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { couponInfoApi, platformCouponAddApi, platformCouponEditApi } from '@/api/marketing';
import { mapGetters } from 'vuex';
import { checkPermi } from '@/utils/permission'; // 权限判断函数
import merchantName from '@/components/merUseCategory';
export default {
  name: 'createCoupon',
  data: function data() {
    return {
      cChannelList: [{
        name: '小程序',
        label: '1'
      }, {
        name: 'H5',
        label: '2'
      }, {
        name: '小程序和H5',
        label: '3'
      }],
      currentTab: '1',
      tabList: [{
        value: '1',
        title: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9m00')
      }, {
        value: '2',
        title: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9os0')
      }],
      loading: false,
      formValidate: {
        channel: '3',
        //领取渠道
        name: '',
        //优惠券名称
        category: 3,
        //适用范围
        money: 1,
        //面值
        minPrice: 0,
        //使用门槛
        receiveType: 1,
        //领取方式
        isTimeReceive: true,
        //领取时间类型
        receiveStartTime: '',
        //可领取开始时间
        receiveEndTime: '',
        //可领取结束时间
        isFixedTime: false,
        day: 1,
        //天数
        useStartTime: '',
        //可使用时间范围 开始时间
        useEndTime: '',
        //可使用时间范围 结束时间
        isLimited: true,
        //是否限量
        total: 1,
        //总数
        num: 1,
        //编辑优惠券发布数量
        isRepeated: false,
        //是否可重复领取
        linkedData: null,
        //关联数据
        status: false,
        validityTime: [],
        //使用有效期
        collectionTime: [] //领取时间
      },
      pickerOptionsForEditCoupon: {
        // 时间有效校验
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      ruleValidate: {
        channel: [{
          required: true,
          message: '请选择领取渠道'
        }],
        name: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j4k80')
        }],
        money: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j4r40')
        }],
        minPrice: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9rk0')
        }],
        receiveType: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9v00')
        }],
        isTimeReceive: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9xg0')
        }],
        day: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j6qs0')
        }],
        total: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j9zo0')
        }],
        isLimited: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja280')
        }],
        isRepeated: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja500')
        }],
        isFixedTime: [{
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja280')
        }],
        validityTime: [{
          type: 'array',
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja7g0'),
          fields: {
            0: {
              //type: 'tpye', //tpye类型试情况而定,所以如果返回的是date就改成date
              required: true,
              trigger: 'change',
              message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja9w0')
            },
            1: {
              //type: 'tpye',
              required: true,
              trigger: 'change',
              message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jac40')
            }
          }
        }],
        collectionTime: [{
          type: 'array',
          required: true,
          message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja7g0'),
          fields: {
            0: {
              //type: 'tpye', //tpye类型试情况而定,所以如果返回的是date就改成date
              required: true,
              trigger: 'change',
              message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5ja9w0')
            },
            1: {
              //type: 'tpye',
              required: true,
              trigger: 'change',
              message: this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jac40')
            }
          }
        }]
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: false
      },
      listLoading: false,
      selectGoods: false,
      multipleSelection: [],
      tableData: {
        data: [],
        total: 0
      },
      proBrandList: '',
      merIds: [],
      ids: [],
      linkedData: null
    };
  },
  components: {
    merchantName: merchantName
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['adminProductClassify', 'productBrand'])), {}, {
    isEdit: function isEdit() {
      return this.$route.params.id ? true : false;
    },
    isCopy: function isCopy() {
      return this.$route.params.copy ? true : false;
    }
  }),
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    this.setTagsViewTitle();
    if (!localStorage.getItem('adminProductClassify')) this.$store.dispatch('product/getAdminProductClassify');
    if (!localStorage.getItem('productBrand')) this.$store.dispatch('product/getMerProductBrand');
    if (this.isEdit) {
      this.getInfo();
    }
  },
  methods: {
    checkPermi: checkPermi,
    //设置标题
    setTagsViewTitle: function setTagsViewTitle() {
      if (this.$route.params.id && this.$route.params.id != 0) {
        var title = this.isEdit && !this.isCopy ? this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j3x00') : this.isCopy ? this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j46w0') : this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j42c0');
        var route = Object.assign({}, this.tempRoute, {
          title: "".concat(title, "-").concat(this.$route.params.id)
        });
        this.$store.dispatch('tagsView/updateVisitedView', route);
      }
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.formValidate.validityTime = e;
      this.formValidate.useStartTime = e ? e[0] : '';
      this.formValidate.useEndTime = e ? e[1] : '';
    },
    // 领取时间
    onchangeTime2: function onchangeTime2(e) {
      this.formValidate.collectionTime = e;
      this.formValidate.receiveStartTime = e ? e[0] : '';
      this.formValidate.receiveEndTime = e ? e[1] : '';
    },
    back: function back() {
      this.$router.push({
        path: '/marketing/PlatformCoupon/list'
      });
    },
    // 获取商户id
    getMerId: function getMerId(id) {
      this.merIds = id;
    },
    addGoods: function addGoods() {
      var _this = this;
      this.$modalGoodList(function (row) {
        _this.listLoading = false;
        _this.tableData.data = row;
      }, 'many', _this.tableData.data);
    },
    //行删除
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;
      this.$modalSure(this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jaeo0')).then(function () {
        _this2.tableData.data.splice(index, 1);
      });
    },
    //批量删除
    batchDel: function batchDel() {
      var _this3 = this;
      this.$modalSure(this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jah80')).then(function () {
        var data = [];
        var _iterator = _createForOfIteratorHelper(_this3.tableData.data),
          _step;
        try {
          var _loop = function _loop() {
            var item1 = _step.value;
            var _index = _this3.multipleSelection.findIndex(function (c) {
              return c.id === item1.id;
            });
            if (_index === -1) {
              data.push(item1);
            }
          };
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this3.tableData.data = data;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectAll: function selectAll(data) {
      var id = data.map(function (i, index) {
        return i.spu_id;
      });
      this.ids = Array.from(new Set([].concat(_toConsumableArray(this.ids), _toConsumableArray(id))));
    },
    selectOne: function selectOne(data, row) {
      var id = data.map(function (i, index) {
        return i.spu_id;
      });
      var index = this.ids.findIndex(function (e) {
        return e == row.spu_id;
      });
      this.ids.splice(index, 1);
      this.ids = Array.from(new Set([].concat(_toConsumableArray(this.ids), _toConsumableArray(id))));
    },
    handleCommand: function handleCommand(command) {
      var _this4 = this;
      this.$message('click on item ' + command);
      this.selectAllPage = command === 'all';
      this.$nextTick(function () {
        _this4.$refs.tableList.toggleAllSelection();
      });
    },
    getInfo: function getInfo() {
      var _this5 = this;
      couponInfoApi(this.$route.params.id).then(function (res) {
        _this5.formValidate = JSON.parse(JSON.stringify(res));
        if (_this5.formValidate.isTimeReceive) {
          _this5.$set(_this5.formValidate, 'collectionTime', [_this5.formValidate.receiveStartTime, _this5.formValidate.receiveEndTime]);
        }
        if (res.category == 6) {
          _this5.merIds = _this5.formValidate.linkedData.split(',');
        } else if (_this5.formValidate.category == 4) {
          _this5.linkedData = Number(_this5.formValidate.linkedData);
        } else if (_this5.formValidate.category == 2) {
          _this5.tableData.data = _this5.formValidate.productList;
        } else if (_this5.formValidate.category == 5) {
          _this5.proBrandList = Number(_this5.formValidate.linkedData);
        }
        if (_this5.formValidate.useStartTime && _this5.formValidate.useEndTime) {
          _this5.$set(_this5.formValidate, 'validityTime', [res.useStartTime, res.useEndTime]);
        }
        _this5.formValidate.num = 1;
      });
    },
    submitForm: function submitForm(formName) {
      var _this6 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this6.currentTab = '2';
        } else {
          _this6.$message.warning(_this6.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jajk0'));
        }
      });
    },
    //保存
    save: function save() {
      var _this7 = this;
      switch (this.formValidate.category) {
        case 6:
          this.formValidate.linkedData = this.merIds.toString();
          break;
        case 2:
          if (!this.tableData.data.length) return this.$message.error(this.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jamc0'));
          var data = [];
          this.tableData.data.map(function (item) {
            return data.push(item.id);
          });
          this.formValidate.linkedData = data.toString();
          break;
        case 4:
          this.formValidate.linkedData = this.linkedData;
          break;
        case 5:
          this.formValidate.linkedData = this.proBrandList.toString();
          break;
        default:
          this.formValidate.linkedData = '';
          break;
      }
      if (this.formValidate.receiveType === 3 || this.formValidate.receiveType == 5) this.formValidate.isTimeReceive = false;
      if (this.isEdit && !this.isCopy) {
        platformCouponEditApi(this.formValidate).then(function (res) {
          _this7.$message.success(_this7.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jap40'));
          _this7.back();
        });
      } else {
        platformCouponAddApi(this.formValidate).then(function (res) {
          _this7.$message.success(_this7.$t('marketing.platformCoupon.couponList.creatCoupon.5tqu9q5jap40'));
          _this7.back();
        });
      }
    }
  }
};