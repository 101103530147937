"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.couponCategory = couponCategory;
/**
 *優惠券 類型
 */
function couponCategory(status) {
  var statusMap = {
    1: '店鋪',
    2: '商品',
    3: '通用',
    4: '品類',
    5: '品牌',
    6: '跨店'
  };
  return statusMap[status];
}