"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var storeApi = _interopRequireWildcard(require("@/api/product.js"));
var _info = _interopRequireDefault(require("./info"));
var _edit = _interopRequireDefault(require("./edit"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "list"
  components: {
    info: _info.default,
    edit: _edit.default
  },
  props: {
    biztype: {
      // 类型，1 产品分类，2 文章分类
      type: Object,
      default: {
        value: -1
      },
      validator: function validator(obj) {
        return obj.value > 0;
      }
    },
    pid: {
      type: Number,
      default: 0,
      validator: function validator(value) {
        return value >= 0;
      }
    },
    selectModel: {
      // 是否选择模式
      type: Boolean,
      default: false
    },
    selectModelKeys: {
      type: Array
    },
    rowSelect: {}
  },
  data: function data() {
    return {
      selectModelKeysNew: this.selectModelKeys,
      loading: false,
      constants: this.$constants,
      treeProps: {
        label: 'name',
        children: 'child'
        // expandTrigger: 'hover',
        // checkStrictly: false,
        // emitPath: false
      },
      // treeCheckedKeys:[],// 选择模式下的属性结构默认选中
      multipleSelection: [],
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        prent: {},
        // 父级对象
        data: {},
        biztype: this.biztype // 统一主业务中的目录类型
      },
      dataList: [],
      listPram: {
        pid: this.pid,
        type: this.biztype.value,
        status: -1,
        name: '',
        page: this.$constants.page.page,
        limit: this.$constants.page.limit[0]
      },
      viewInfoConfig: {
        data: null,
        visible: false
      },
      defaultImg: require('@/assets/imgs/moren.jpg')
    };
  },
  mounted: function mounted() {
    if (this.biztype.value === 2) {
      if ((0, _permission.checkPermi)(['platform:article:category:list'])) this.handlerGetList();
    } else {
      if ((0, _permission.checkPermi)(['platform:product:page:list'])) this.handlerGetTreeList();
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //权限控制
    onchangeIsShow: function onchangeIsShow(row) {
      var _this = this;
      if (this.biztype.value === 2) {
        articleApi.articleCategorySwitchApi(row.id).then(function () {
          _this.$message.success(_this.$t('operateBtn.editSucess'));
          localStorage.clear('articleClass');
          _this.handlerGetList();
        }).catch(function () {
          row.status = !row.status;
        });
      } else {
        storeApi.productCategoryShowApi(row.id).then(function () {
          _this.$message.success(_this.$t('operateBtn.editSucess'));
          _this.$store.commit('product/SET_AdminProductClassify', []);
          _this.handlerGetTreeList();
        }).catch(function () {
          row.isShow = !row.isShow;
        });
      }
    },
    handleEditMenu: function handleEditMenu(rowData) {
      this.editDialogConfig.isCreate = 1;
      this.editDialogConfig.data = rowData;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.visible = true;
    },
    handleAddMenu: function handleAddMenu(rowData) {
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.data = {};
      this.editDialogConfig.biztype = this.biztype;
      this.editDialogConfig.visible = true;
    },
    getCurrentNode: function getCurrentNode(data) {
      var node = this.$refs.tree.getNode(data);
      this.childNodes(node);
      // this.parentNodes(node);
      //是否编辑的表示
      // this.ruleForm.isEditorFlag = true;
      //编辑时候使用
      this.$emit('rulesSelect', this.$refs.tree.getCheckedKeys());
      // this.selectModelKeys = this.$refs.tree.getCheckedKeys();
      //无论编辑和新增点击了就传到后台这个值
      // this.$emit('rulesSelect', this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys()));
      // this.ruleForm.menuIdsisEditor = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
    },
    //具体方法可以看element官网api
    childNodes: function childNodes(node) {
      var len = node.childNodes.length;
      for (var i = 0; i < len; i++) {
        node.childNodes[i].checked = node.checked;
        this.childNodes(node.childNodes[i]);
      }
    },
    parentNodes: function parentNodes(node) {
      if (node.parent) {
        for (var key in node) {
          if (key == 'parent') {
            node[key].checked = true;
            this.parentNodes(node[key]);
          }
        }
      }
    },
    handleDelete: function handleDelete(rowData) {
      var _this2 = this;
      this.$modalSure(this.biztype.value === 2 ? this.$t('operateBtn.delTips') : this.$t('product.category.delTips')).then(function () {
        if (_this2.biztype.value === 2) {
          articleApi.articleCategoryDelApi(rowData).then(function (data) {
            _this2.handlerGetList();
            localStorage.clear('articleClass');
            _this2.$message.success(_this2.$t('operateBtn.delSucess'));
          });
        } else {
          storeApi.productCategoryDeleteApi(rowData.id).then(function (data) {
            _this2.handlerGetTreeList();
            _this2.$message.success(_this2.$t('operateBtn.delSucess'));
          });
        }
      });
    },
    handlerGetList: function handlerGetList() {
      var _this3 = this;
      articleApi.articleCategoryListApi().then(function (data) {
        _this3.dataList = data;
        var list = data.filter(function (item) {
          return item.status;
        });
        localStorage.setItem('articleClass', JSON.stringify(list));
      });
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this4 = this;
      storeApi.productCategoryListApi().then(function (res) {
        var obj = {},
          dataList = [];
        res.forEach(function (item) {
          obj = item;
          obj.parentId = item.pid;
          obj.children = [];
          dataList.push(obj);
        });
        _this4.dataList = _this4.handleTree(dataList, 'menuId');
        _this4.loading = false;
      }).catch(function () {
        _this4.loading = false;
      });
    },
    handlerGetInfo: function handlerGetInfo(id) {
      this.viewInfoConfig.data = id;
      this.viewInfoConfig.visible = true;
    },
    handleNodeClick: function handleNodeClick(data) {
      console.log('data:', data);
    },
    handleAddArrt: function handleAddArrt(treeData) {
      var _result = selfUtil.addTreeListLabel(treeData);
      return _result;
    },
    hideEditDialog: function hideEditDialog() {
      var _this5 = this;
      setTimeout(function () {
        _this5.editDialogConfig.prent = {};
        _this5.editDialogConfig.type = 0;
        _this5.editDialogConfig.visible = false;
        if (_this5.biztype.value === 2) {
          _this5.handlerGetList();
        } else {
          _this5.handlerGetTreeList();
        }
      }, 200);
    },
    handleSelectionChange: function handleSelectionChange(d1, _ref) {
      var checkedNodes = _ref.checkedNodes,
        checkedKeys = _ref.checkedKeys,
        halfCheckedNodes = _ref.halfCheckedNodes,
        halfCheckedKeys = _ref.halfCheckedKeys;
      // this.multipleSelection =  checkedKeys.concat(halfCheckedKeys)
      this.multipleSelection = checkedKeys;
      this.$emit('rulesSelect', this.multipleSelection);
    }
  }
};