"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.adminAccountUpdate = adminAccountUpdate;
exports.adminAdd = adminAdd;
exports.adminDel = adminDel;
exports.adminInfo = adminInfo;
exports.adminList = adminList;
exports.adminUpdate = adminUpdate;
exports.menuAdd = menuAdd;
exports.menuDelete = menuDelete;
exports.menuInfo = menuInfo;
exports.menuListApi = menuListApi;
exports.menuUpdate = menuUpdate;
exports.menuUpdateShowStatus = menuUpdateShowStatus;
exports.sensitiveListApi = sensitiveListApi;
exports.updateStatusApi = updateStatusApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function adminDel(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/admin/delete',
    method: 'GET',
    params: data
  });
}
function adminInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/admin/info',
    method: 'GET',
    params: data
  });
}
function adminList(params) {
  return (0, _request.default)({
    url: '/admin/platform/admin/list',
    method: 'GET',
    params: params
  });
}
function adminAdd(pram) {
  var data = {
    account: pram.account,
    level: pram.level,
    pwd: pram.pwd,
    realName: pram.realName,
    roles: pram.roles.join(','),
    status: pram.status,
    phone: pram.phone
  };
  return (0, _request.default)({
    url: '/admin/platform/admin/save',
    method: 'POST',
    data: data
  });
}
function adminUpdate(pram) {
  var data = {
    account: pram.account,
    phone: pram.phone,
    pwd: pram.pwd,
    roles: pram.roles,
    realName: pram.realName,
    status: pram.status,
    id: pram.id,
    isDel: pram.isDel
  };
  return (0, _request.default)({
    url: '/admin/platform/admin/update',
    method: 'POST',
    data: data
  });
}

/**
 * 修改状态
 * @param pram
 */
function updateStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/admin/updateStatus",
    method: 'get',
    params: params
  });
}

/**
 * 权限规则菜单列表
 * @param pram
 */
function menuListApi(params) {
  var data = {
    menuType: params.menuType,
    //菜单类型:M-目录，C-菜单，A-按钮
    name: params.name //菜单名称
  };
  return (0, _request.default)({
    url: "/admin/platform/menu/list",
    method: 'get',
    params: data
  });
}

/**
 * 权限规则新增菜单
 * @param data
 */
function menuAdd(data) {
  var systemMenuRequest = data;
  return (0, _request.default)({
    url: "/admin/platform/menu/add",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 权限规则删除菜单
 * @param data
 */
function menuDelete(id) {
  return (0, _request.default)({
    url: "/admin/platform/menu/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 权限规则菜单详情
 * @param data
 */
function menuInfo(id) {
  return (0, _request.default)({
    url: "/admin/platform/menu/info/".concat(id),
    method: 'get'
  });
}

/**
 * 权限规则菜单修改
 * @param data
 */
function menuUpdate(data) {
  var systemMenuRequest = data;
  return (0, _request.default)({
    url: "/admin/platform/menu/update",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 权限规则修改菜单显示状态
 * @param data
 */
function menuUpdateShowStatus(id) {
  return (0, _request.default)({
    url: "admin/platform/menu/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 敏感操作日志
 * @param data
 */
function sensitiveListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/log/sensitive/list",
    method: 'get',
    params: params
  });
}

/**
 * 修改登录用户信息
 * @param data
 */
function adminAccountUpdate(pram) {
  var data = {
    password: pram.pwd,
    realName: pram.realName
  };
  return (0, _request.default)({
    url: '/admin/platform/login/admin/update',
    method: 'POST',
    data: data
  });
}