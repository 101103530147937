"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.merCategoryFilter = merCategoryFilter;
exports.merCreateTypeFilter = merCreateTypeFilter;
exports.merchantTypeFilter = merchantTypeFilter;
exports.selfTypeFilter = selfTypeFilter;
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//商户過濾器

/**
 * 商户分類
 */
function merCategoryFilter(status) {
  if (!status) {
    return '';
  }
  var arrayList = _store.default.getters.merchantClassify;
  var array = arrayList.filter(function (item) {
    return status === item.id;
  });
  if (array.length) {
    return array[0].name;
  } else {
    return '';
  }
}

/**
 * 商鋪類型
 */
function merchantTypeFilter(status) {
  if (!status) {
    return '';
  }
  var arrayList = _store.default.getters.merchantType;
  var array = arrayList.filter(function (item) {
    return status === item.id;
  });
  if (array.length) {
    return array[0].name;
  } else {
    return '';
  }
}

/**
 * 商户創建類型
 */
function merCreateTypeFilter(status) {
  var statusMap = {
    admin: '管理員創建',
    apply: '商户入駐申請'
  };
  return statusMap[status];
}

/**
 * 商户類別
 */
function selfTypeFilter(status) {
  var statusMap = {
    true: '自營',
    false: '非自營'
  };
  return statusMap[status];
}