"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configApi = configApi;
exports.configUpdateApi = configUpdateApi;
exports.promoterListApi = promoterListApi;
exports.spreadClearApi = spreadClearApi;
exports.spreadListApi = spreadListApi;
exports.spreadOrderListApi = spreadOrderListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 分销设置 -- 详情
 */
function configApi() {
  return (0, _request.default)({
    url: '/admin/platform/retail/store/config/get',
    method: 'get'
  });
}

/**
 * @description 分销设置 -- 表单提交
 */
function configUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/retail/store/config/save',
    method: 'post',
    data: data
  });
}

/**
 * @description 分销员 -- 列表
 */
function promoterListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/retail/store/people/list',
    method: 'get',
    params: params
  });
}

/**
 * @description 根据条件获取下级推广用户列表
 */
function spreadListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/retail/store/sub/user/list',
    method: 'get',
    params: params
  });
}

/**
 * @description 推广人订单 -- 列表
 */
function spreadOrderListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/retail/store/promotion/order/list',
    method: 'get',
    params: params
  });
}

/**
 * @description 推广人 -- 清除上级推广人
 */
function spreadClearApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/retail/store/clean/spread/".concat(id),
    method: 'get'
  });
}