function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { userListApi, tagAllListApi, tagPiApi, foundsApi, updateSpreadApi, updatePhoneApi, balanceApi } from '@/api/user';
import { spreadClearApi } from '@/api/distribution';
import detailUser from '@/components/detailUser';
import editFrom from './edit';
import levelEdit from './level';
import XLSX from 'xlsx';
import userList from '@/components/userList';
import couponList from '../components/couponList';
import * as logistics from '@/api/logistics.js';
import Cookies from 'js-cookie';
import { checkPermi } from '@/utils/permission'; // 权限判断函数
import { Debounce } from '@/utils/validate';
import { getLocaleI18n } from '@/utils';
export default {
  name: 'UserIndex',
  components: {
    editFrom: editFrom,
    userList: userList,
    levelEdit: levelEdit,
    detailUser: detailUser,
    couponList: couponList
  },
  filters: {
    sexFilter: function sexFilter(status) {
      var statusMap = {
        0: getLocaleI18n('user.list.index.5tqlp5tzqps0'),
        1: getLocaleI18n('user.list.index.5tqlp5tzqug0'),
        2: getLocaleI18n('user.list.index.5tqlp5tzqzk0'),
        3: getLocaleI18n('user.list.index.5tqlp5tzr4g0')
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      firstLoginTimeVal: [],
      lastLoginTimeVal: [],
      registerTypeList: [{
        value: 'wechat',
        label: this.$t('user.list.index.5tqlp5tzvyo0')
      }, {
        value: 'routine',
        label: this.$t('user.list.index.5tqlp5tzw0o0')
      }, {
        value: 'h5',
        label: 'H5'
      }, {
        value: 'iosWx',
        label: this.$t('user.list.index.5tqlp5tzw280')
      }, {
        value: 'androidWx',
        label: this.$t('user.list.index.5tqlp5tzw4k0')
      }, {
        value: 'ios',
        label: 'ios'
      }],
      formExtension: {
        spreadUid: '',
        userId: ''
      },
      selectedUser: {
        image: '',
        nickname: ''
      },
      ruleInline: {},
      extensionVisible: false,
      userVisible: false,
      levelInfo: '',
      pickerOptions: this.$timeOptions,
      loadingBtn: false,
      PointValidateForm: {
        integral: null,
        operateType: 'add',
        uid: '',
        money: null
      },
      loadingPoint: false,
      VisiblePoint: false,
      visible: false,
      dialogVisible: false,
      levelVisible: false,
      labelData: [],
      selData: [],
      keywords: '',
      labelPosition: 'right',
      collapse: false,
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      phoneLengthCheckList: [{
        label: 1,
        name: '澳門'
      }, {
        label: 2,
        name: '大陸'
      }],
      userFrom: {
        tagIds: '',
        sex: '',
        registerType: '',
        payCount: '',
        accessType: 0,
        phoneLengthCheck: '',
        firstLoginTime: '',
        lastLoginTime: '',
        dateLimit: '',
        nikename: '',
        phone: '',
        page: 1,
        limit: 15
      },
      phone: '',
      grid: {
        xl: 8,
        lg: 12,
        md: 12,
        sm: 24,
        xs: 24
      },
      labelLists: [],
      selectedData: [],
      timeVal: [],
      dynamicValidateForm: {
        groupId: []
      },
      loading: false,
      groupIdFrom: [],
      selectionList: [],
      batchName: '',
      uid: 0,
      Visible: false,
      keyNum: 0,
      multipleSelectionAll: [],
      idKey: 'uid',
      card_select_show: false,
      checkAll: false,
      checkedCities: ['ID', this.$t('user.list.index.5tqlp5tztjw0'), this.$t('user.list.index.5tqlp5tztnw0'), this.$t('user.list.index.5tqlp5tztz40'), this.$t('user.list.index.5tqlp5tztp40'), this.$t('user.list.index.5tqlp5tztrk0'), this.$t('user.list.index.5tqlp5tztv00'), this.$t('user.list.index.5tqlp5tztws0')],
      columnData: ['ID', this.$t('user.list.index.5tqlp5tztjw0'), this.$t('user.list.index.5tqlp5tztnw0'), this.$t('user.list.index.5tqlp5tztz40'), this.$t('user.list.index.5tqlp5tztp40'), this.$t('user.list.index.5tqlp5tztrk0'), this.$t('user.list.index.5tqlp5tztv00'), this.$t('user.list.index.5tqlp5tztws0')],
      isIndeterminate: true,
      type: 'integral',
      userInfo: {},
      checkedIds: [],
      selectAllPage: false,
      visibleCoupon: false //发送优惠券显示
    };
  },
  activated: function activated() {
    this.userFrom.nikename = '';
    this.getList(1);
  },
  mounted: function mounted() {
    if (checkPermi(['platform:user:page:list'])) this.getList(1);
    if (checkPermi(['platform:user:tag:all:list'])) this.getTagList();
  },
  methods: {
    checkPermi: checkPermi,
    filterRegisterType: function filterRegisterType(status) {
      var statusMap = {
        wechat: '#FD5ACC',
        routine: '#A277FF',
        h5: '#E8B600',
        iosWx: '#1BBE6B',
        androidWx: '#EF9C20',
        ios: '#1890FF'
      };
      return statusMap[status];
    },
    // 清除
    clearSpread: function clearSpread(row) {
      var _this = this;
      this.$modalSure(this.$t('user.list.index.5tqlp5tzw7k0') + row.nickname + '】的上级推广人吗').then(function () {
        spreadClearApi(row.id).then(function (res) {
          _this.$message.success(_this.$t('user.list.index.5tqlp5tzw9c0'));
          _this.getList();
        });
      });
    },
    onSubExtension: function onSubExtension(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          updateSpreadApi(_this2.formExtension).then(function (res) {
            _this2.$message.success(_this2.$t('user.list.index.5tqlp5tzwc00'));
            _this2.extensionVisible = false;
            _this2.getList();
          });
        } else {
          return false;
        }
      });
    },
    getTemplateRow: function getTemplateRow(row) {
      this.formExtension.spreadUid = row.id;
      this.selectedUser = {
        image: row.avatar,
        nickname: row.nickname
      };
    },
    setExtension: function setExtension(row) {
      this.formExtension = {
        spreadUid: '',
        userId: row.id
      };
      this.selectedUser = {
        image: '',
        nickname: ''
      };
      this.extensionVisible = true;
    },
    handleCloseExtension: function handleCloseExtension() {
      this.extensionVisible = false;
      this.selectedUser = {
        image: '',
        nickname: ''
      };
    },
    modalPicTap: function modalPicTap() {
      this.userVisible = true;
    },
    resetForm: function resetForm() {
      this.visible = false;
    },
    reset: function reset(formName) {
      this.userFrom = {
        tagIds: '',
        sex: '',
        isPromoter: '',
        payCount: '',
        accessType: 0,
        phoneLengthCheck: '',
        dateLimit: '',
        firstLoginTime: '',
        lastLoginTime: '',
        province: '',
        city: '',
        page: 1,
        limit: 15,
        nikename: ''
      };
      this.keywords = '';
      this.phone = '';
      this.labelData = [];
      this.timeVal = [];
      this.firstLoginTimeVal = [];
      this.lastLoginTimeVal = [];
      this.getList();
    },
    Close: function Close() {
      this.Visible = false;
      this.levelVisible = false;
    },
    // 账户详情
    onDetails: function onDetails(id) {
      this.$refs.userDetailFrom.getUserDetail(id);
      this.$refs.userDetailFrom.dialogUserDetail = true;
    },
    // 积分余额
    editPoint: function editPoint(row, type) {
      this.uid = row.id;
      this.type = type;
      this.userInfo = row;
      this.VisiblePoint = true;
    },
    // 积分余额
    submitPointForm: Debounce(function (formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.PointValidateForm.uid = _this3.uid;
          _this3.loadingBtn = true;
          if (_this3.type === 'integral') {
            foundsApi(_this3.PointValidateForm).then(function (res) {
              _this3.$message.success(_this3.$t('user.list.index.5tqlp5tzwc00'));
              _this3.loadingBtn = false;
              _this3.handlePointClose();
              _this3.getList();
            }).catch(function () {
              _this3.loadingBtn = false;
            });
          } else {
            balanceApi(_this3.PointValidateForm).then(function (res) {
              _this3.$message.success(_this3.$t('user.list.index.5tqlp5tzwc00'));
              _this3.loadingBtn = false;
              _this3.handlePointClose();
              _this3.getList();
            }).catch(function () {
              _this3.loadingBtn = false;
            });
          }
        } else {
          return false;
        }
      });
    }),
    // 积分余额
    handlePointClose: function handlePointClose() {
      this.VisiblePoint = false;
      this.PointValidateForm = {
        integral: null,
        operateType: 'add',
        uid: '',
        money: null
      };
    },
    editUser: function editUser(row) {
      this.userInfo = row;
      this.visible = true;
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          tagPiApi({
            tagIds: _this4.dynamicValidateForm.groupId.join(','),
            ids: _this4.checkedIds.toString()
          }).then(function (res) {
            _this4.$message.success(_this4.$t('user.list.index.5tqlp5tzwc00'));
            _this4.loading = false;
            _this4.handleClose();
            _this4.getList();
            _this4.$refs.table.clearSelection();
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    setBatch: function setBatch(name, row) {
      this.batchName = name;
      if (row) {
        this.checkedIds = row.id;
        this.dynamicValidateForm.groupId = row.tagId ? row.tagId.split(',').map(Number) : [];
      } else {
        this.dynamicValidateForm.groupId = '';
      }
      if (this.multipleSelectionAll.length === 0 && !row) return this.$message.warning(this.$t('user.list.index.5tqlp5tzwe80'));
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.$refs['dynamicValidateForm'].resetFields();
    },
    // 搜索
    userSearchs: function userSearchs() {
      this.userFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.userFrom.dateLimit = e ? this.timeVal.join(',') : '';
    },
    onchangeTimea: function onchangeTimea(e) {
      this.firstLoginTimeVal = e;
      this.userFrom.firstLoginTime = e ? this.firstLoginTimeVal.join(',') : '';
    },
    onchangeTimeb: function onchangeTimeb(e) {
      this.lastLoginTimeVal = e;
      this.userFrom.lastLoginTime = e ? this.lastLoginTimeVal.join(',') : '';
    },
    //标签列表
    getTagList: function getTagList() {
      var _this5 = this;
      tagAllListApi().then(function (res) {
        _this5.labelLists = res;
        localStorage.setItem('tagAllList', JSON.stringify(res));
      });
    },
    // 列表
    getList: function getList(num) {
      var _this6 = this;
      this.listLoading = true;
      this.userFrom.page = num ? num : this.userFrom.page;
      this.userFrom.tagIds = this.labelData.join(',');
      this.userFrom.nikename = encodeURIComponent(this.keywords);
      this.userFrom.phone = encodeURIComponent(this.phone);
      userListApi(this.userFrom).then(function (res) {
        _this6.tableData.data = res.list;
        _this6.tableData.total = res.total;
        if (_this6.selectAllPage == 'all') {
          _this6.$nextTick(function () {
            _this6.$refs.tableList.toggleAllSelection();
          });
        }
        _this6.listLoading = false;
      }).catch(function () {
        _this6.listLoading = false;
      });
      this.checkedCities = this.$cache.local.has('user_stroge') ? this.$cache.local.getJSON('user_stroge') : this.checkedCities;
      this.$set(this, 'card_select_show', false);
    },
    // 设置选中的方法
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelectionAll = val;
      var data = [];
      this.multipleSelectionAll.map(function (item) {
        data.push(item.id);
      });
      this.checkedIds = data;
    },
    selectAll: function selectAll(data) {
      var id = data.map(function (i, index) {
        return i.id;
      });
      this.checkedIds = Array.from(new Set([].concat(_toConsumableArray(this.checkedIds), _toConsumableArray(id))));
    },
    selectOne: function selectOne(data, row) {
      var id = data.map(function (i, index) {
        return i.id;
      });
      var index = this.checkedIds.findIndex(function (e) {
        return e == row.id;
      });
      this.checkedIds.splice(index, 1);
      this.checkedIds = Array.from(new Set([].concat(_toConsumableArray(this.checkedIds), _toConsumableArray(id))));
    },
    pageChange: function pageChange(page) {
      this.userFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.userFrom.limit = val;
      this.getList(1);
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this7 = this;
      this.$modalSure().then(function () {
        productDeleteApi(id).then(function () {
          _this7.$message.success(_this7.$t('user.list.index.5tqlp5tzwgc0'));
          _this7.getList(1);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this8 = this;
      row.isShow ? putOnShellApi(row.id).then(function () {
        _this8.$message.success(_this8.$t('user.list.index.5tqlp5tzwj80'));
        _this8.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      }) : offShellApi(row.id).then(function () {
        _this8.$message.success(_this8.$t('user.list.index.5tqlp5tzwkw0'));
        _this8.getList();
      }).catch(function () {
        row.isShow = !row.isShow;
      });
    },
    renderHeader: function renderHeader(h) {
      var _this9 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('user.list.index.5tqlp5tzu1o0')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this9.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.card_select_show = false;
      this.$modal.loading(this.$t('user.list.index.5tqlp5tzwmo0'));
      this.$cache.local.setJSON('user_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    },
    //发送优惠券
    sendCoupon: function sendCoupon() {
      if (!this.checkedIds.length) {
        this.$message.warning(this.$t('user.list.index.5tqlp5tzwpg0'));
      } else {
        this.visibleCoupon = true;
      }
    },
    sendSuccess: function sendSuccess() {
      this.visibleCoupon = false;
    },
    exportAllData: function exportAllData() {
      var _this10 = this;
      var data = _objectSpread({}, this.userFrom);
      data.limit = 999999;
      data.page = 1;
      userListApi(data).then(function (res) {
        console.log(res, 'aaaa');
        _this10.$modal.msgSuccess('导出成功');
        _this10.exportDataToExcel(res.list, '用户列表.xlsx');
      }).catch(function () {});
    },
    exportDataToExcel: function exportDataToExcel(data, filename) {
      var _this11 = this;
      var statusMap = {
        0: getLocaleI18n('user.list.index.5tqlp5tzqps0'),
        1: getLocaleI18n('user.list.index.5tqlp5tzqug0'),
        2: getLocaleI18n('user.list.index.5tqlp5tzqzk0'),
        3: getLocaleI18n('user.list.index.5tqlp5tzr4g0')
      };
      // 1. 获取表头
      var labelValues = ['ID', '姓名', '手機號', '積分', '註冊類型', '生日', '性別'];
      // 去除数组里面是空的
      var newList = labelValues.filter(function (item) {
        return item !== '';
      });
      // 2. 获取数据
      var dataArray = data.map(function (obj, index) {
        var arr = [obj.id, obj.nickname, obj.phone, obj.integral, obj.registerType ? _this11.registerTypeList.filter(function (item) {
          return item.value == obj.registerType;
        })[0].label : '', obj.birthday, statusMap[obj.sex]];
        return arr;
      });
      dataArray.unshift(newList);

      // 3. 导出Excel
      var worksheet = XLSX.utils.aoa_to_sheet(dataArray);
      var workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, filename);
    }
  }
};