"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pagediyAddApi2 = pagediyAddApi2;
exports.pagediyDeleteApi = pagediyDeleteApi;
exports.pagediyDeleteApi2 = pagediyDeleteApi2;
exports.pagediyGetSetHome = pagediyGetSetHome;
exports.pagediyGetSetHomeX = pagediyGetSetHomeX;
exports.pagediyInfoApi = pagediyInfoApi;
exports.pagediyInfoApi2 = pagediyInfoApi2;
exports.pagediyListApi = pagediyListApi;
exports.pagediyListApi2 = pagediyListApi2;
exports.pagediyQingyongApi = pagediyQingyongApi;
exports.pagediySaveApi = pagediySaveApi;
exports.pagediySetdefaultApi = pagediySetdefaultApi;
exports.pagediySetdefaultApiX = pagediySetdefaultApiX;
exports.pagediyUpdateApi = pagediyUpdateApi;
exports.pagediyUpdateApi2 = pagediyUpdateApi2;
exports.pagediyUpdatenameApi = pagediyUpdatenameApi;
exports.wechatQrcodeApi = wechatQrcodeApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description diy 新增
 */
function pagediySaveApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/pagediy/save',
    method: 'post',
    data: data
  });
}

/**
 * @description diy 列表
 */
function pagediyListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/pagediy/list',
    method: 'get',
    params: params
  });
}
/**
 * @description diy 列表2
 */
function pagediyListApi2(params) {
  return (0, _request.default)({
    url: '/admin/platform/popdiy/list',
    method: 'get',
    params: params
  });
}
/**
 * @description diy 详情
 */
function pagediyInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/info/".concat(id),
    method: 'get'
  });
}
/**
 * @description diy 详情2
 */
function pagediyInfoApi2(id) {
  return (0, _request.default)({
    url: "/admin/platform/popdiy/info/".concat(id),
    method: 'get'
  });
}

/**
 * @description diy 修改
 */
function pagediyUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/update",
    method: 'post',
    data: data
  });
}
/**
 * @description diy 修改2
 */
function pagediyUpdateApi2(data) {
  return (0, _request.default)({
    url: "/admin/platform/popdiy/update",
    method: 'post',
    data: data
  });
}
/**
 * @description diy 新增2
 */
function pagediyAddApi2(data) {
  return (0, _request.default)({
    url: "/admin/platform/popdiy/save",
    method: 'post',
    data: data
  });
}
/**
 * @description diy 删除2
 */
function pagediyDeleteApi2(params) {
  return (0, _request.default)({
    url: "/admin/platform/popdiy/delete",
    method: 'get',
    params: params
  });
}
/**
 * @description diy 启用
 */
function pagediyQingyongApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/popdiy/setPopDefault/".concat(id),
    method: 'get'
  });
}
/**
 * @description diy 设置商城首页
 */
function pagediySetdefaultApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/setdefault/".concat(id),
    method: 'get'
  });
}

/**
 * @description diy 设置小程序商城首页
 */
function pagediySetdefaultApiX(id) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/ma/setdefault/".concat(id),
    method: 'get'
  });
}

/**
 * @description diy 设置商城删除
 */
function pagediyDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/delete",
    method: 'get',
    params: params
  });
}

/**
 * 查询已经设置的首页diy 模版id
 * @returns {*}
 */
function pagediyGetSetHome() {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/getdefault",
    method: 'get'
  });
}
function pagediyGetSetHomeX() {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/ma/getdefault",
    method: 'get'
  });
}

/**
 * 获取小程序二维码
 * @returns {*}
 */
function wechatQrcodeApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/get/qrcode",
    method: 'post',
    data: data
  });
}

/**
 * DIY 模版名称更新
 * @returns {*}
 */
function pagediyUpdatenameApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pagediy/updatename",
    method: 'post',
    data: data
  });
}