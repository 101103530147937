"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategroy = addCategroy;
exports.categroyByIds = categroyByIds;
exports.categroyUpdateStatus = categroyUpdateStatus;
exports.deleteCategroy = deleteCategroy;
exports.infoCategroy = infoCategroy;
exports.treeCategroy = treeCategroy;
exports.updateCategroy = updateCategroy;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 新增分类
 * @param pram
 */
function addCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url
  };
  return (0, _request.default)({
    url: '/admin/platform/category/save',
    method: 'POST',
    data: data
  });
}

/**
 * 分类详情
 * @param pram
 */
function infoCategroy(id) {
  return (0, _request.default)({
    url: "/admin/platform/category/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 删除分类
 * @param pram
 */
function deleteCategroy(id) {
  return (0, _request.default)({
    url: "/admin/platform/category/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 分类数据tree数据
 * @param pram
 */
function treeCategroy(pram) {
  var data = {
    type: pram.type,
    status: pram.status,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/platform/category/list/tree',
    method: 'GET',
    params: data
  });
}

/**
 * 更新分类
 * @param pram
 */
function updateCategroy(pram) {
  var data = {
    extra: pram.extra,
    name: pram.name,
    pid: pram.pid,
    sort: pram.sort,
    status: pram.status,
    type: pram.type,
    url: pram.url,
    id: pram.id
  };
  return (0, _request.default)({
    url: "/admin/platform/category/update/".concat(pram.id),
    method: 'POST',
    data: data
  });
}

/**
 * 根据id集合查询对应分类列表
 * @param pram
 */
function categroyByIds(pram) {
  var data = {
    ids: pram.ids
  };
  return (0, _request.default)({
    url: '/admin/platform/category/list/ids',
    method: 'GET',
    params: data
  });
}

/**
 * 修改 显示关闭状态
 * @param pram
 */
function categroyUpdateStatus(id) {
  return (0, _request.default)({
    url: "/admin/platform/category/updateStatus/".concat(id),
    method: 'post'
  });
}