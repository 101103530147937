"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.communityCategoryAddApi = communityCategoryAddApi;
exports.communityCategoryDelApi = communityCategoryDelApi;
exports.communityCategoryListApi = communityCategoryListApi;
exports.communityCategoryShowApi = communityCategoryShowApi;
exports.communityCategoryUpdateApi = communityCategoryUpdateApi;
exports.communityConfigApi = communityConfigApi;
exports.communityConfigUpdateApi = communityConfigUpdateApi;
exports.communityNoteAuditApi = communityNoteAuditApi;
exports.communityNoteBatchUpdateApi = communityNoteBatchUpdateApi;
exports.communityNoteDelApi = communityNoteDelApi;
exports.communityNoteDetailApi = communityNoteDetailApi;
exports.communityNoteForcedDownApi = communityNoteForcedDownApi;
exports.communityNoteListApi = communityNoteListApi;
exports.communityNoteReplyOffApi = communityNoteReplyOffApi;
exports.communityReplyAuditApi = communityReplyAuditApi;
exports.communityReplyDelApi = communityReplyDelApi;
exports.communityReplyListApi = communityReplyListApi;
exports.communityReplyNoteListApi = communityReplyNoteListApi;
exports.communityStarUpdateApi = communityStarUpdateApi;
exports.communityTopicAddApi = communityTopicAddApi;
exports.communityTopicDelApi = communityTopicDelApi;
exports.communityTopicListApi = communityTopicListApi;
exports.communityTopicRecommendApi = communityTopicRecommendApi;
exports.communityTopicUpdateApi = communityTopicUpdateApi;
exports.communitycCategoryBatchApi = communitycCategoryBatchApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 添加社区分类
 * @param pram
 */
function communityCategoryAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/add",
    method: 'post',
    data: data
  });
}

/**
 * 删除社区分类
 * @param pram
 */
function communityCategoryDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 社区分类显示开关
 * @param pram
 */
function communityCategoryShowApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/show/".concat(id),
    method: 'post'
  });
}

/**
 * 社区分类分页列表
 * @param pram
 */
function communityCategoryListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/page/list",
    method: 'get',
    params: params
  });
}

/**
 * 编辑社区分类
 * @param pram
 */
function communityCategoryUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/update",
    method: 'post',
    data: data
  });
}

/**
 * 社区内容审核
 * @param pram
 */
function communityNoteAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/audit",
    method: 'post',
    data: data
  });
}

/**
 * 社区内容分类批量修改
 * @param pram
 */
function communityNoteBatchUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/category/batch/update",
    method: 'post',
    data: data
  });
}

/**
 * 社区内容删除
 * @param pram
 */
function communityNoteDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 社区内容详情
 * @param pram
 */
function communityNoteDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/detail/".concat(id),
    method: 'GET'
  });
}

/**
 * 社区内容强制下架
 * @param pram
 */
function communityNoteForcedDownApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/forced/down/".concat(data.id),
    method: 'POST',
    data: data
  });
}

/**
 * 社区笔记评论强制关闭开关
 * @param pram
 */
function communityNoteReplyOffApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/reply/force/off/".concat(id),
    method: 'POST'
  });
}

/**
 * 社区内容分页列表
 * @param pram
 */
function communityNoteListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/page/list",
    method: 'GET',
    params: params
  });
}

/**
 * 获取社区配置
 * @param pram
 */
function communityConfigApi() {
  return (0, _request.default)({
    url: "/admin/platform/community/category/get/config",
    method: 'GET'
  });
}

/**
 * 更新社区配置
 * @param pram
 */
function communityConfigUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/category/update/config",
    method: 'post',
    data: data
  });
}

/**
 * 社区评论审核
 * @param pram
 */
function communityReplyAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/reply/audit",
    method: 'post',
    data: data
  });
}

/**
 * 社区评论删除
 * @param pram
 */
function communityReplyDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/reply/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 社区评论-文章分页列表
 * @param pram
 */
function communityReplyNoteListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/community/reply/note/page/list/".concat(params.nid),
    method: 'get',
    params: params
  });
}

/**
 * 社区评论分页列表
 * @param pram
 */
function communityReplyListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/community/reply/page/list",
    method: 'get',
    params: params
  });
}

/**
 * 添加社区话题
 * @param pram
 */
function communityTopicAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/topic/add",
    method: 'post',
    data: data
  });
}

/**
 * 删除社区话题
 * @param pram
 */
function communityTopicDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/topic/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 社区话题分页列表
 * @param pram
 */
function communityTopicListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/community/topic/page/list",
    method: 'get',
    params: params
  });
}

/**
 * 社区话题开启/关闭推荐
 * @param pram
 */
function communityTopicRecommendApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/community/topic/recommend/".concat(id),
    method: 'POST'
  });
}

/**
 * 编辑社区话题
 * @param pram
 */
function communityTopicUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/topic/update",
    method: 'POST',
    data: data
  });
}

/**
 * 推荐星级编辑
 * @param data
 */
function communityStarUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/star/update",
    method: 'POST',
    data: data
  });
}

/**
 * 社区笔记分类批量修改
 */
function communitycCategoryBatchApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/community/note/category/batch/update",
    method: 'POST',
    data: data
  });
}