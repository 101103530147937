import { render, staticRenderFns } from "./configCategroy.vue?vue&type=template&id=89ddb1d0&scoped=true"
import script from "./configCategroy.vue?vue&type=script&lang=js"
export * from "./configCategroy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89ddb1d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\platform-management-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89ddb1d0')) {
      api.createRecord('89ddb1d0', component.options)
    } else {
      api.reload('89ddb1d0', component.options)
    }
    module.hot.accept("./configCategroy.vue?vue&type=template&id=89ddb1d0&scoped=true", function () {
      api.rerender('89ddb1d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/maintain/devconfig/configCategroy.vue"
export default component.exports