"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formTempNameInfoApi = formTempNameInfoApi;
exports.getFormConfigEdit = getFormConfigEdit;
exports.getFormConfigInfo = getFormConfigInfo;
exports.getFormConfigList = getFormConfigList;
exports.getFormConfigSave = getFormConfigSave;
exports.notificationDetail = notificationDetail;
exports.notificationListApi = notificationListApi;
exports.notificationRoutine = notificationRoutine;
exports.notificationSms = notificationSms;
exports.notificationUpdate = notificationUpdate;
exports.notificationWechat = notificationWechat;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getFormConfigInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/system/form/temp/info',
    method: 'GET',
    params: data
  });
}
function getFormConfigList(pram) {
  var data = {
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/platform/system/form/temp/list',
    method: 'GET',
    params: data
  });
}
function getFormConfigSave(pram) {
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/platform/system/form/temp/save',
    method: 'POST',
    data: data
  });
}
function getFormConfigEdit(pram) {
  var params = {
    id: pram.id
  };
  var data = {
    content: pram.content,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/platform/system/form/temp/update',
    method: 'POST',
    params: params,
    data: data
  });
}

/**
 * 系统通知列表
 * @param pram
 */
function notificationListApi(pram) {
  var data = {
    sendType: pram.sendType
    //发送类型（1：通知会员，2：通知平台）
  };
  return (0, _request.default)({
    url: '/admin/platform/notification/list',
    method: 'GET',
    params: data
  });
}

/**
 * 小程序订阅模板开关
 * @param pram
 */
function notificationRoutine(id) {
  return (0, _request.default)({
    url: "/admin/platform/notification/routine/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 公众号模板开关
 * @param pram
 */
function notificationWechat(id) {
  return (0, _request.default)({
    url: "/admin/platform/notification/wechat/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 发送短信开关
 * @param pram
 */
function notificationSms(id) {
  return (0, _request.default)({
    url: "/admin/platform/notification/sms/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 通知详情
 * @param pram
 */
function notificationDetail(param) {
  var data = {
    detailType: param.type,
    id: param.id
  };
  return (0, _request.default)({
    url: "/admin/platform/notification/detail",
    method: 'get',
    params: data
  });
}

//admin/system/notification/detail
/**
 * 修改通知
 * @param pram
 */
function notificationUpdate(param) {
  var data = {
    detailType: param.type,
    id: param.id,
    status: param.status,
    tempId: param.tempId
  };
  return (0, _request.default)({
    url: "/admin/platform/notification/update",
    method: 'post',
    data: data
  });
}

/**
 * 通过名称查询详情
 * @param pram
 */
function formTempNameInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/system/form/temp/name/info",
    method: 'get',
    params: params
  });
}