"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _devise = require("@/api/devise");
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _vue = _interopRequireDefault(require("vue"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'index',
  components: {
    linkaddress: _linkaddress.default
  },
  data: function data() {
    return {
      MaDefaultID: '',
      //小程序首页ID
      grid: {
        xl: 16,
        lg: 20,
        md: 20,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 12,
        lg: 12,
        md: 20,
        sm: 24,
        xs: 24
      },
      isPopDefault: null,
      binajiId: '',
      formLabelWidth: '100px',
      listActive: 0,
      titles: '新增',
      defaultImg: require('@/assets/imgs/moren.jpg'),
      tabList: [this.$t('pagediy.devise.index.5trmcttk7kk0'), this.$t('pagediy.devise.index.5trmcttk7o00'), this.$t('pagediy.devise.index.5trmcttk7kk1'), this.$t('pagediy.devise.index.5trmcttk7o07')],
      updateTrigger: 0,
      formT: {
        img: '',
        name: '',
        url: '',
        region: '',
        time: []
      },
      tableForm: {
        page: 1,
        limit: 10,
        name: ''
      },
      name: '',
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableData2: {
        data: [],
        total: 0
      },
      frontDomain: '',
      perViewDia: {
        // 列表二维码预览
        visible: false,
        perViewUrl: ''
      },
      perViewDia2: {
        visible: false,
        perViewUrl: ''
      },
      Qrcode: '',
      //小程序二维码
      // 用一个字符串来保存当前双击的是哪一个单元格
      currentCell: null
    };
  },
  mounted: function mounted() {
    //判断移动端配置地址中是否包含https://，http://
    var url = ['https://', 'http://'];
    var frontDomain = localStorage.getItem('frontDomain');
    var contains = url.some(function (item) {
      return frontDomain.includes(item);
    });
    this.frontDomain = contains ? frontDomain : "https://".concat(frontDomain);
    if ((0, _permission.checkPermi)(['platform:pagediy:list'])) this.getList();
    if ((0, _permission.checkPermi)(['platform:pagediy:list'])) this.getList2(1);
    // this.getWechatQrcode();
    this.getQRcode();
    console.log(this.$t('pagediy.devise.index.5trmcttk7kk0'), 11111112222);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    colose: function colose() {
      this.formT = {
        img: '',
        name: '',
        url: '',
        region: '',
        time: []
      };
      this.perViewDia2.visible = false;
    },
    linkUrl: function linkUrl(e) {
      this.formT.url = e;
    },
    getLink: function getLink() {
      this.$refs.linkaddres.dialogVisible = true;
    },
    // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
    tableCellClassName: function tableCellClassName(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      row.index = rowIndex;
      column.index = columnIndex;
    },
    formatTimeRange: function formatTimeRange(timeArray) {
      if (!timeArray || timeArray.length !== 2) {
        return '';
      }
      var startDate = new Date(timeArray[0].replace('T', ' ').replace('Z', ''));
      var endDate = new Date(timeArray[1].replace('T', ' ').replace('Z', ''));
      var formattedStart = "".concat(startDate.getFullYear(), "-").concat(this.padZero(startDate.getMonth() + 1), "-").concat(this.padZero(startDate.getDate()), " ").concat(this.padZero(startDate.getHours()), ":").concat(this.padZero(startDate.getMinutes()), ":").concat(this.padZero(startDate.getSeconds()));
      var formattedEnd = "".concat(endDate.getFullYear(), "-").concat(this.padZero(endDate.getMonth() + 1), "-").concat(this.padZero(endDate.getDate()), " ").concat(this.padZero(endDate.getHours()), ":").concat(this.padZero(endDate.getMinutes()), ":").concat(this.padZero(endDate.getSeconds()));
      return "".concat(formattedStart, " \u81F3 ").concat(formattedEnd);
    },
    padZero: function padZero(num) {
      return num < 10 ? "0".concat(num) : num;
    },
    // 点击图片
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _vue.default.set(_this.formT, 'img', img[0].sattDir); // 使用 Vue.set
        }
      }, tit, 'store');
    },
    //启用
    handlerQiyongDiyPage: function handlerQiyongDiyPage(data) {
      var _this2 = this;
      (0, _devise.pagediyQingyongApi)(data).then(function (res) {
        if (res == 200) {
          _this2.$message.success('启用成功');
          _this2.getList2(1);
        }
      });
    },
    // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
    // 拼接后类似这样："1,0","1,1",
    handleName: function handleName(row, column) {
      var _this3 = this;
      this.currentCell = row.index + ',' + column.index;
      // 这里必须要setTimeout，因为在点击的时候，input才刚被v-if显示出来，不然拿不到dom
      setTimeout(function () {
        // 双击后自动获得焦点
        _this3.$refs[row.index + ',' + column.index].focus();
      });
    },
    // 新增/编辑
    tSave: function tSave() {
      var _this4 = this;
      if (this.titles == '新增') {
        (0, _devise.pagediyAddApi2)({
          value: this.formT,
          isPopDefault: 0
        }).then(function (res) {
          console.log(res, 11111111111);
          _this4.$message.success('新增成功');
          _this4.perViewDia2.visible = false;
          _this4.formT = {};
          _this4.getList2(1);
        });
      } else {
        (0, _devise.pagediyUpdateApi2)({
          value: this.formT,
          isPopDefault: this.isPopDefault,
          id: this.binajiId
        }).then(function (res) {
          if (res == 200) {
            _this4.$message.success('编辑成功');
            _this4.getList2(1);
            _this4.perViewDia2.visible = false;
            _this4.formT = {};
            _this4.binajiId = '';
            _this4.isPopDefault = null;
          }
        });
      }
    },
    // 当input失去焦点的时候，隐藏input
    hideInput: function hideInput(item) {
      var _this5 = this;
      if (!item.name) {
        return this.$message.warning(this.$t('pagediy.devise.index.5trmcttk7rc0'));
      } else {
        this.currentCell = null;
      }
      (0, _devise.pagediyUpdatenameApi)({
        id: item.id,
        name: item.name
      }).then(function (res) {
        _this5.$message.success(_this5.$t('pagediy.devise.index.5trmcttk7vc0'));
      });
    },
    //微信二维码
    getQRcode: function getQRcode() {
      document.getElementById('diyQrcode').innerHTML = '';
      new _qrcodejs.default('diyQrcode', {
        width: 120,
        height: 120,
        text: this.frontDomain
      });
    },
    //小程序二维码
    getWechatQrcode: function getWechatQrcode() {
      var _this6 = this;
      (0, _devise.wechatQrcodeApi)({
        scene: 'id=0',
        path: 'pages/index/index',
        env_version: 'trial'
      }).then(function (res) {
        _this6.Qrcode = res.code;
      });
    },
    //点击左侧菜单
    ProductNavTab: function ProductNavTab(index) {
      this.listActive = index;
    },
    //预览
    previewProtol: function previewProtol(id) {
      if (!id) return;
      this.perViewDia.perViewUrl = this.frontDomain + '?id=' + id;
      this.perViewDia.visible = true;
    },
    // 使用模板
    setHomepage: function setHomepage(id) {
      var _this7 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk7zo0')).then(function () {
        (0, _devise.pagediySetdefaultApi)(id).then(function (res) {
          _this7.$message.success(_this7.$t('pagediy.devise.index.5trmcttk85k0'));
          _this7.getList();
        });
      });
    },
    // 小程序使用模板
    setHomepageX: function setHomepageX(id) {
      var _this8 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk7zo0')).then(function () {
        (0, _devise.pagediySetdefaultApiX)(id).then(function (res) {
          _this8.$message.success(_this8.$t('pagediy.devise.index.5trmcttk85k0'));
          _this8.getList();
        });
      });
    },
    // DIY 编辑首页
    handlerDiyHomePage: function handlerDiyHomePage() {
      var _this9 = this;
      (0, _devise.pagediyGetSetHome)().then(function (homePageId) {
        _this9.handlerEditDiyPage(homePageId);
      });
    },
    // DIY 小程序编辑首页
    handlerDiyHomePageX: function handlerDiyHomePageX() {
      var _this10 = this;
      (0, _devise.pagediyGetSetHomeX)().then(function (homePageId) {
        _this10.handlerEditDiyPage(homePageId);
      });
    },
    // 添加
    handlerEditDiyPage: function handlerEditDiyPage(id) {
      var _this$$router$resolve = this.$router.resolve({
          path: "/page/design/creatDevise/".concat(id)
        }),
        href = _this$$router$resolve.href;
      window.open(href);
    },
    // 添加弹框
    handlerAddDiyPageT: function handlerAddDiyPageT() {
      this.titles = '新增';
      this.perViewDia2.visible = true;
    },
    // 编辑弹框
    handlerEditDiyPageT: function handlerEditDiyPageT(row) {
      console.log(row, 'Rrrrrr');
      this.isPopDefault = row.isPopDefault;
      this.binajiId = row.id;
      this.formT = row.value;
      console.log(row, this.binajiId, 'his.binajiId');
      this.titles = '编辑';
      this.perViewDia2.visible = true;
    },
    getList: function getList(num) {
      var _this11 = this;
      this.listLoading = true;
      this.tableForm.page = num ? num : this.tableForm.page;
      this.tableForm.name = encodeURIComponent(this.name);
      (0, _devise.pagediyListApi)(this.tableForm).then(function (res) {
        _this11.listLoading = false;
        _this11.tableData.data = res.list;
        _this11.tableData.total = res.total;
      }).then(function (res) {
        _this11.listLoading = false;
      });
      var obj = {
        limit: 999,
        name: encodeURIComponent(this.name)
      };
      (0, _devise.pagediyListApi)(obj).then(function (res) {
        _this11.MaDefaultID = res.list.filter(function (item) {
          return item.isMaDefault == 1;
        })[0].id;
        console.log(_this11.MaDefaultID);
      });
    },
    getList2: function getList2(num) {
      var _this12 = this;
      this.listLoading = true;
      this.tableForm.page = num ? num : this.tableForm.page;
      this.tableForm.name = encodeURIComponent(this.name);
      (0, _devise.pagediyListApi2)(this.tableForm).then(function (res) {
        console.log(res, 321654798789);
        _this12.listLoading = false;
        _this12.tableData2.data = res.list;
        _this12.tableData2.data.map(function (item) {
          item.value = JSON.parse(item.value);
          // 确保 item.img 存在且为字符串
          if (item.value.img && typeof item.value.img === 'string') {
            var lastIndex = item.value.img.lastIndexOf('https://');
            if (lastIndex !== -1) {
              item.value.img = item.value.img.substring(lastIndex); // 从最后一个 https:// 开始提取
            }
          } else {
            // 处理 item.img 不存在或非字符串的情况
            item.img = ''; // 或者可以根据需求赋其他默认值
          }
        });
        _this12.tableData2.total = res.total;
      }).then(function (res) {
        _this12.listLoading = false;
      });
      var obj = {
        limit: 999,
        name: encodeURIComponent(this.name)
      };
      (0, _devise.pagediyListApi2)(obj).then(function (res) {
        _this12.MaDefaultID = res.list.filter(function (item) {
          return item.isMaDefault == 1;
        })[0].id;
        console.log(_this12.MaDefaultID);
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this13 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk88k0')).then(function () {
        (0, _devise.pagediyDeleteApi)({
          id: id
        }).then(function (res) {
          _this13.$message.success(_this13.$t('pagediy.devise.index.5trmcttk8cw0'));
          if (_this13.tableData.data.length === 1 && _this13.tableForm.page > 1) _this13.tableForm.page = _this13.tableForm.page - 1;
          _this13.getList();
        });
      });
    },
    handleDelete2: function handleDelete2(id, idx) {
      var _this14 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk88k0')).then(function () {
        (0, _devise.pagediyDeleteApi)({
          id: id
        }).then(function (res) {
          _this14.$message.success(_this14.$t('pagediy.devise.index.5trmcttk8cw0'));
          if (_this14.tableData.data.length === 1 && _this14.tableForm.page > 1) _this14.tableForm.page = _this14.tableForm.page - 1;
          _this14.getList2();
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableForm.page = val;
      this.getList();
    }
  }
};