"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = wechat;
exports.generatorWxUrl = generatorWxUrl;
exports.getWXCodeByUrl = getWXCodeByUrl;
exports.isPhone = isPhone;
exports.isWeixin = isWeixin;
exports.loginByWxCode = loginByWxCode;
exports.ready = ready;
exports.wechatEvevt = wechatEvevt;
var _client = _interopRequireDefault(require("wechat-jssdk/dist/client.umd"));
var _wxApi = require("@/api/wxApi");
var _auth = require("@/utils/auth");
var _utils = require("@/utils");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var STATE_KEY = 'wx_authorize_state';
var WX_AUTH = 'wx_auth';
var BACK_URL = 'login_back_url';
var LOGINTYPE = 'loginType';
var instance;
var wechatObj;
var LONGITUDE = 'user_longitude';
var LATITUDE = 'user_latitude';
var WECHAT_SCRIPT_URL = '//res.wx.qq.com/open/js/jweixin-1.6.0.js';

/**
 * 是否是微信
 */
function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
}

/**
 * 是否是移动端
 */
function isPhone() {
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
}
function wechat() {
  return new Promise(function (resolve, reject) {
    if (instance) return resolve(instance);
    (0, _wxApi.getWechatConfig)().then(function (res) {
      var _wx = (0, _client.default)(res);
      wechatObj = _wx;
      _wx.initialize().then(function () {
        instance = _wx.wx;
        instance.initConfig = res;
        resolve(instance);
      }).catch(reject);
    }).catch(function (err) {
      reject(err);
    });
  });
}
function loginByWxCode(code) {
  return new Promise(function (resolve, reject) {
    var loginType = (0, _auth.getToken)();
    (0, _wxApi.wechatAuth)(code).then(function (res) {
      _store.default.commit('SET_TOKEN', res.token);
      (0, _auth.setToken)(res.token);
      _jsCookie.default.set(WX_AUTH, code);
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}
function getWXCodeByUrl(path, step) {
  if ((0, _auth.getToken)()) return;
  generatorWxUrl(path, step);
}
function generatorWxUrl(path, step) {
  wechat().then(function (wx) {
    window.location.href = getAuthUrl(wx.initConfig, path, step);
  }).catch(function (err) {
    reject(err);
  });
}
function getAuthUrl(config, path, step) {
  var finalUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=".concat(config.appId, "&redirect_uri=").concat(encodeURIComponent(path), "&response_type=code&scope=snsapi_base&state=").concat(step, "#wechat_redirect");
  return finalUrl;
}
function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var reg_rewrite = new RegExp('(^|/)' + name + '/([^/]*)(/|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  var q = window.location.pathname.substr(1).match(reg_rewrite);
  if (r != null) {
    return unescape(r[2]);
  } else if (q != null) {
    return unescape(q[2]);
  } else {
    return null;
  }
}

/**
 * 公众号事件
 * @param name 事件名
 * @param config 配置
 * @returns {Promise<unknown>}
 */
function wechatEvevt(name, config) {
  return new Promise(function (resolve, reject) {
    var wx;
    var configDefault = {
      fail: function fail(res) {
        if (wx) return reject({
          is_ready: true,
          wx: wx
        });
        (0, _wxApi.getWechatConfig)().then(function (res) {
          wechatObj.signSignature({
            nonceStr: res.nonceStr,
            signature: res.signature,
            timestamp: res.timestamp
          });
          wx = wechatObj.getOriginalWx();
          reject({
            is_ready: true,
            wx: wx
          });
        });
      },
      success: function success(res) {
        resolve(res);
      },
      cancel: function cancel(err) {
        reject(err);
      },
      complete: function complete(err) {
        reject(err);
      }
    };
    Object.assign(configDefault, config);
    (0, _wxApi.getWechatConfig)().then(function (res) {
      var _wx = (0, _client.default)(res);
      _wx.initialize().then(function () {
        instance = _wx.getOriginalWx();
        instance.ready(function () {
          if (_typeof(name) === 'object') {
            name.forEach(function (item) {
              instance[item] && instance[item](configDefault);
            });
          } else instance[name] && instance[name](configDefault);
        });
      });
    });
  });
}
function ready() {
  return new Promise(function (resolve) {
    if (typeof instance !== 'undefined') {
      instance.ready(function () {
        resolve(instance);
      });
    } else {
      (0, _wxApi.getWechatConfig)().then(function (res) {
        var _wx = (0, _client.default)(res);
        _wx.initialize().then(function () {
          instance = _wx.wx;
          instance.ready(function () {
            resolve(instance);
          });
        });
      });
    }
  });
}